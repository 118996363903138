import restApiSlice from "../../services/rest-api/slice"
import { transformQuotation } from "./utils/transformData"
import transformErrorResponse from "./utils/transformApiResponse"

// TODO: need endpoint to query quotations, to edit and delete quotation, to get price quote, to validate price quote, (to add quote-> in FUTURE)
//   TODO: Edit quotation mutation
//   TODO: Delete quotation mutation
//   TODO: Add quotation mutation
const orderManagementRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getQuotations: builder.query({
      query: () => ({ url: "/quotations", method: "GET" }),
      providesTags: ["OrderManagement"],
      transformResponse: (response) => {
        // First transform the data
        const transformedData = response.data.map((row) => ({
          ...row.processedRequest,
          shippingOptions:{
            ...row.processedRequest.shippingOptions,
            shippingDate: new Date().toISOString().split('T')[0],
          },
          shippingType: row.processedRequest?.shippingType || "cheapest",
          createdDate: row.createdDate,
        }))
        return transformedData
      },
    }),
    updateQuotation: builder.mutation({
      query: ({ id, payload }) => ({
        url: `/quotations/${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["OrderManagement"],
      transformResponse: (response) => {
        return response.data
      },
      transformErrorResponse: (error) => {
        return transformErrorResponse(error)
      }
    }),
    createQuotation: builder.mutation({
      query: ({ payload }) => ({
        url: `/quotations`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["OrderManagement"],
      transformResponse: (response) => {
        return response.data
      },
      transformErrorResponse: (error) => {
        return transformErrorResponse(error)
      },
    }),
    deleteQuotation: builder.mutation({
      query: (rateRequestId) => ({
        url: `/quotations/${rateRequestId}`,
        method: "DELETE",
      }),
      // invalidatesTags: ["OrderManagement"], // TODO: do I want to invalidate the cache and refetch? Will remove quotes
      transformResponse: (response) => response.data,
      transformErrorResponse: (error) => {
        return transformErrorResponse(error)
      }
    }),
    uploadQuotationsFile: builder.mutation({
      query: (payload) => ({ url: "/s3/get-presigned-url", method: "POST", body: payload }),
      invalidatesTags: ["OrderManagement"],
      transformResponse: (response) => response.data,
    }),
    obtainQuotes: builder.mutation({
      query: (payload) => ({
        url: "/bulk/rates",
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => {
        return response.data
      },
      transformErrorResponse: (error) => {
        return transformErrorResponse(error)
      }
    }),
    // TODO: invalidate the correct cache
    onAccountPayment: builder.mutation({
      query: ({ payload }) => ({ url: "/payments/onaccount", method: "POST", body: payload }),
      invalidatesTags: ["PendingShipments"],
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useGetQuotationsQuery, useUpdateQuotationMutation, useCreateQuotationMutation, useUploadQuotationsFileMutation, useObtainQuotesMutation, useOnAccountPaymentMutation, useDeleteQuotationMutation } = orderManagementRestApiSlice

export default orderManagementRestApiSlice.reducer
