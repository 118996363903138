const shippingOptions = ["cheapest", "fastest"]

const CARRIER_CODES = [
  'federal-express',
  'purolator-express',
  'canada-post',
  'dhl-express',
  'canpar-express',
  'day-ross-commerce',
  'day-ross-freight',
  'nationex',
  'purolator-freight',
  'gls-parcel',
  'gls-freight',
  'fedex-freight',
  'ups-parcel',
  'FEDEX_INTERNATIONAL_PRIORITY_EXPRESS',
  'FEDEX_INTERNATIONAL_PRIORITY'
];


export { shippingOptions, CARRIER_CODES }