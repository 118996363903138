import React from "react"
import { useTranslation } from "react-i18next"
import { Button, CircularProgress, Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import SoeCheckCircleIcon from "../../../../../../../../soe-theme/src/icons/SoeCheckCircleIcon"
import { pxToRem } from "../../../../../../../../soe-theme/src/utils"

function BulkActionExecuteButton({ onClick, disabled, isLoading, curBulkAction }) {
  const { t } = useTranslation("orderManagement")

  return (
    <Tooltip title={t(`buttons.bulkAction.${curBulkAction}.tooltip`)} placement="top">
      <div>
        <Button variant="contained" color="primary" onClick={onClick} disabled={disabled || isLoading}
          sx={(theme)=>({
            backgroundColor: theme.palette.success.main,
            "&:hover":{
              backgroundColor: theme.palette.success.dark,
          }
          })}
        >
          {
            isLoading ?
            <CircularProgress size={pxToRem(24)} sx={{ color: "white" }} /> // font size 24px is default for the SoeCheckCircleIcon
            :
            <SoeCheckCircleIcon fontSize="medium" />
          }
        </Button>
      </div>
    </Tooltip>
  )
}

BulkActionExecuteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  curBulkAction: PropTypes.string.isRequired,
}

BulkActionExecuteButton.defaultProps = {
  disabled: false,
  isLoading: false
}

export default BulkActionExecuteButton
