import React from "react"
import { useTranslation } from "react-i18next"
import ModalButtonsRow from "../../../../../../../generic/components/modal-buttons-row"
import { useQuotationContext } from "../../../../../../../../../state/quotation-provider"
import { useValidationContext } from "../../../../../../state/provider"
import useOnAccountPayment from "../../../../../../../../../hooks/make-account-payment"

function ButtonsRow() {
  const { t } = useTranslation("orderManagement")
  const { validationFlowWizard } = useQuotationContext()
  const { processPayments } = useOnAccountPayment()
  const { closeValidationModal } = useValidationContext()

  const handleCancel = () => {
    closeValidationModal();
  }

  const handleMakePayment = async () => {
    try {
      await processPayments()
    } catch (error) {
      console.error("Error processing payments:", error);
    } finally {
      validationFlowWizard.goToNamedStep("payment-results")
    }
  }

  return (
    <ModalButtonsRow onLeftButtonClick={handleMakePayment} onRightButtonClick={handleCancel} leftButtonLabel={t("modals.validation.buttons.pay.label")} rightButtonLabel={t("modals.validation.buttons.cancel.label")} />
  )
}

export default ButtonsRow
