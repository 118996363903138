import { billDutyToListCC, businessRelationshipList, importExportList } from "../../../utils/international-options"
import { FREIGHT_OPTIONS } from "../../../utils/constants"

export const transformQuotationForMutation = (quotation) => {
  const cleanedQuotation = {
    orderId: quotation.orderId,
    shippingType: quotation.shippingType,
    shipper: quotation.shipper,
    recipient: quotation.recipient,
    packages: quotation.packages,
    shippingOptions: quotation.shippingOptions,
    billingOptions: quotation.billingOptions,
    freightOptions: quotation.freightOptions,
    internationalOptions: quotation.internationalOptions,
    buyer: quotation.buyer,
    broker: quotation.broker,
    commercialInvoice: quotation.commercialInvoice,
    source: quotation.source,
    rateRequestId: quotation.rateRequestId,
  }

  return {
    "processedRequest": cleanedQuotation
  }
}

const transformInternationalOptions = (internationalOptions) => {
  if (!internationalOptions || !Object.keys(internationalOptions).length === 0) return {}
  const documentsOnly = typeof internationalOptions.documentsOnly === "boolean"
    ? internationalOptions.documentsOnly
    : undefined

  const billDutyTo = billDutyToListCC.some(option => option.value === internationalOptions.billDutyTo)
    ? internationalOptions.billDutyTo
    : undefined

  const businessRelationship = businessRelationshipList.some(option => option.value === internationalOptions.businessRelationship)
    ? internationalOptions.businessRelationship
    : undefined

  const exportType = importExportList.some(option => option.value === internationalOptions.exportType)
    ? internationalOptions.exportType
    : undefined

  const senderIsProducer = typeof internationalOptions.senderIsProducer === "boolean"
    ? internationalOptions.senderIsProducer
    : undefined

  const dutyAccountNumber = internationalOptions.dutyAccountNumber?.replace(/\s+/g, "")

  const brokerRequired = typeof internationalOptions.brokerRequired === "boolean"
    ? internationalOptions.brokerRequired
    : undefined

  return {
    ...internationalOptions,
    documentsOnly,
    billDutyTo,
    businessRelationship,
    exportType,
    senderIsProducer,
    dutyAccountNumber,
    brokerRequired,
  }

}

// TODO: finish buyer
const transformBuyer = (buyer) => {
  if (!buyer) return undefined
  return buyer
}

// TODO: finish
const transformBroker = (broker) => {
  if (!broker) return undefined
  return broker
}

const transformCommercialInvoice = (commercialInvoice) => {
  if (!commercialInvoice) return undefined
  return commercialInvoice
}

const transformFreightOptions = (freightOptions) => {
  const appointmentDateRegex = /^([0-9]{4}-[0-9]{2}-[0-9]{2})$/
  const appointmentTimeRegex = /^(([0-1]?[0-9]|2[0-3]):[0-5][0-9])$/

  if (!freightOptions || !Object.keys(freightOptions).length === 0) return {}

  const appointment = typeof freightOptions.appointment === "string"
    ? freightOptions.appointment
    : undefined

  const afterHours = FREIGHT_OPTIONS.AFTER_HOURS.some(option => option.value === freightOptions.afterHours)
    ? freightOptions.afterHours
    : undefined

  const twoPersons = FREIGHT_OPTIONS.TWO_PERSONS.some(option => option.value === freightOptions.twoPersons)
    ? freightOptions.twoPersons
    : undefined

  const tailgate = FREIGHT_OPTIONS.TAILGATE.some(option => option.value === freightOptions.tailgate)
    ? freightOptions.tailgate
    : undefined

  const appointmentDate =
    (freightOptions.prebookedAppointment?.appointmentDate !== undefined && new RegExp(appointmentDateRegex, "g").test(freightOptions.prebookedAppointment?.appointmentDate))
    ? freightOptions.prebookedAppointment?.appointmentDate
    : undefined

  const appointmentTime =
    (freightOptions.prebookedAppointment?.appointmentTime !==undefined && new RegExp(appointmentTimeRegex, "g").test(freightOptions.prebookedAppointment?.appointmentTime))
      ? freightOptions.prebookedAppointment?.appointmentTime
      : undefined


  const prebookedAppointment = appointmentDate && appointmentTime
    ? {
      appointmentDate,
      appointmentTime
    }
    : undefined

  return {
    ...freightOptions,
    appointment,
    afterHours,
    twoPersons,
    tailgate,
    prebookedAppointment,
  }
}

export const transformQuotation = (row) => {
  const shipper = {
    ...row.shipper,
  }

  const recipient = {
    ...row.recipient,
  }

  const packages = {
    ...row.packages,
  }

  const shippingOptions = {
    ...row.shippingOptions,
  }

  const billingOptions = {
    ...row.billingOptions,
  }

  const freightOptions = transformFreightOptions(row.freightOptions)

  const internationalOptions = transformInternationalOptions(row.internationalOptions)

  const broker = transformBroker(row.broker)

  const buyer = transformBuyer(row.buyer)

  const commercialInvoice = transformCommercialInvoice(row.commercialInvoice)

  const result = {
    ...row,
    shipper,
    recipient,
    packages,
    shippingOptions,
    billingOptions,
  };

  if (buyer) {
    result.buyer = buyer;
  }

  if (broker) {
    result.broker = broker;
  }
  if (commercialInvoice) {
    result.commercialInvoice = commercialInvoice;
  }

  if (freightOptions) {
    result.freightOptions = freightOptions;
  }

  if (internationalOptions) {
    result.internationalOptions = internationalOptions;
  }

  return result;

}