import React from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography, Modal, Paper } from "@mui/material"
import { pxToRem } from "../../../../../soe-theme/src/utils"
import OrderSummaryCards from "./components/order-summary-cards"
import RateRequestForm from "./components/rate-request-form"
import { useQuotationContext } from "../../../state/quotation-provider"
import CloseFormButton from "./components/buttons/close-form-button"

function OrderDetailsModal() {
  const { showOrderDetailsModal, selectedQuotation, closeRateRequestModal, showRateRequestForm, setShowRateRequestForm } = useQuotationContext()
  const { t } = useTranslation("orderManagement")
  if (!selectedQuotation) return null

  const onCloseModal = () => {
    closeRateRequestModal()
  }

  const onCloseForm = () => {
    setShowRateRequestForm(false)
  }

  return(
    <Modal open={showOrderDetailsModal} onClose={onCloseModal} sx={{display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Paper elevation={2} sx={{height: "80%", width: "70%", display: "flex", flexDirection: "column", borderRadius: `${pxToRem(5)}`}}>
        <Box style={{
          width:"100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          position:"sticky",
          top:0,
          backgroundColor: "transparent",
          zIndex: 1,
          padding: `${pxToRem(10)} ${pxToRem(20)}`,
          borderBottomWidth: `${pxToRem(1)}`,
          borderBottomColor: "black"
        }}
        >
          <Box sx={{ flex: 1 }}>
            {showRateRequestForm && (
              <CloseFormButton onClick={onCloseForm} />
            )}
          </Box>
          <Typography sx={{ flex: 4, fontSize: pxToRem(35), textAlign: "center" }}>{t("modals.orderDetails.title")}</Typography>
          <Box sx={{ flex: 1 }} />
        </Box>
        <RateRequestForm />{/* always needs to be rendered so that the rateRequestWizard gets initialized */}
        {!showRateRequestForm && <OrderSummaryCards />}
      </Paper>
    </Modal>
  )
}

export default OrderDetailsModal
