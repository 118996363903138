import React from 'react';
import TextField from "@mui/material/TextField"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

function OrderIdTextField({ orderId, setOrderId }) {
  const { t } = useTranslation("orderManagement")
  return (
    <Box sx={(theme)=> ({ display: "flex", flexDirection: "row", alignItems: "center", gap: theme.spacing(2) })}>
      <Typography sx={{ whiteSpace: "nowrap" }}>{t("modals.orderDetails.orderId")} : </Typography>
      <TextField
        sx={{ flexShrink: 1 }}
        required
        value={orderId}
        onChange={(event) => setOrderId(event.target.value)}
        margin="dense"
        id="orderId"
        name="orderId"
        type="number"
        variant="standard"
      />
    </Box>
  )
}

OrderIdTextField.propTypes = {
  orderId: PropTypes.string,
  setOrderId: PropTypes.func.isRequired,
}

OrderIdTextField.defaultProps = {
  orderId: "",
}

export default OrderIdTextField
