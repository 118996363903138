import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import Divider from "@mui/material/Divider"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import RateRequestSummaryCard from "../rate-request-summary-card"

import { isBillingOptionsComplete } from "../quotations/utils"
import { BillingOptionsProps } from "../../models/rate-request"

function BillingOptionsCard({
  billingOptions,
  setShowRateRequestDrawer,
  isEditable,
  isValid: customIsBillingOptionsComplete,
  ...sw })
{
  const { t } = useTranslation(["rateRequest", "quotations"])
  const theme = useTheme()

  const showRateRequestDrawer = () => {
    sw.stepWizard.goToNamedStep("billing-options")
    setShowRateRequestDrawer(true)
  }

  const summaryStatusProps = {
    variant: customIsBillingOptionsComplete !== undefined
      ? (customIsBillingOptionsComplete ? "success" : "warning")
      : (isBillingOptionsComplete(billingOptions) ? "success" : "warning" )
  }

  return (
    <RateRequestSummaryCard title={t("billingOptionsTitle", { ns: "quotations" })} handleClick={showRateRequestDrawer} summaryStatusProps={summaryStatusProps} showStatus={isEditable} isClickable={isEditable}>
      {billingOptions && (
        <Box mt={1} sx={{ display: "flex", flexDirection: "column" }}>
          {billingOptions.type && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {billingOptions.type === "thirdParty" ? "Third-Party" : "My Account"}
            </Typography>
          )}

          {billingOptions.type === "thirdParty" && <Divider style={{ marginBottom: 5 }} />}
          {billingOptions?.thirdPartyShipperNumber && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {billingOptions.thirdPartyShipperNumber}
            </Typography>
          )}
          {billingOptions?.postalCode && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {billingOptions.postalCode}
            </Typography>
          )}
          {billingOptions?.countryCode && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {billingOptions.countryCode}
            </Typography>
          )}
          {billingOptions?.name && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {billingOptions.name}
            </Typography>
          )}
        </Box>
      )}
    </RateRequestSummaryCard>
  )
}

BillingOptionsCard.propTypes = {
  billingOptions: BillingOptionsProps,
  setShowRateRequestDrawer: PropTypes.func,
  isEditable: PropTypes.bool,
  isValid: PropTypes.bool,
}

BillingOptionsCard.defaultProps = {
  billingOptions: undefined,
  setShowRateRequestDrawer: () => {},
  isEditable: true,
  isValid: undefined,
}

export default BillingOptionsCard
