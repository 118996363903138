import React from "react"
import { SoeDrawerWizard } from "../../../../../../../soe-theme/src/components"
import { useQuotationContext } from "../../../../../state/quotation-provider"
import QuotesValidation from "./components/quotes-validation"
import ValidationResults from "./components/validation-results"
import ConfirmPayment from "./components/confirm-payment"
import PaymentResults from "./components/payment-results"

function ValidationStepWizard() {
  const { setValidationFlowWizard } = useQuotationContext()

  return (
    <SoeDrawerWizard setStepWizardInstance={setValidationFlowWizard}>
      <QuotesValidation stepName="validation" />
      <ValidationResults stepName="validation-results" />
      <ConfirmPayment stepName="confirm-payment" />
      <PaymentResults stepName="payment-results" />
    </SoeDrawerWizard>
  )
}

export default ValidationStepWizard
