import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import MuiWrapper from "../../../../../../main/style"
import CarrierServiceLogo from "../../../../../../carrier-service-logo"
import DateTime from "../../../../../../date-time"

function CarrierDetailsCard({ service, carrier, commitment, charges, isThirdParty, ...muiBoxProps }) {
  const { t, i18n } = useTranslation(["carrierDetails", "quotations"])
  const theme = useTheme()

  return (
    <MuiWrapper {...muiBoxProps} style={{ margin: 0, display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Card variant="elevation">
        <CardContent style={{ padding:0 }}>
          <CarrierServiceLogo code={carrier.code} serviceName={service?.fullName} logo={carrier.logo} align="center" />
          <Box component="div" justifyContent="center" display="flex">
            {commitment.commitDate || commitment.date ? (
              <DateTime date={commitment.commitDate || commitment.date} time={commitment.commitTime} timezoneCode={commitment.timezoneCode} timezone={commitment.timezone} />
            ) : (
              <Typography variant="inherit" component="span" sx={{ lineHeight: theme.utils.pxToRem(24) }}>
                {t("quote.noDeliveryDate", { ns: "quotations" })}
              </Typography>
            )}
          </Box>
        </CardContent>
      </Card>
    </MuiWrapper>
  )
}

CarrierDetailsCard.propTypes = {
  service: PropTypes.shape({
    fullName: PropTypes.shape({}),
    chips: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  carrier: PropTypes.shape({
    logo: PropTypes.string,
    code: PropTypes.string,
  }).isRequired,
  commitment: PropTypes.shape({
    commitDate: PropTypes.string,
    date: PropTypes.string,
    commitTime: PropTypes.string,
    timezoneCode: PropTypes.string,
    timezone: PropTypes.string,
  }).isRequired,
  charges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isThirdParty: PropTypes.bool,
}

CarrierDetailsCard.defaultProps = {
  isThirdParty: false,
}
export default CarrierDetailsCard
