import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@mui/material"
import { useGetAddressFormatsQuery, useGetCountriesQuery } from "../../../../../../../../../services/address-form/slice"
import FullAddressForm from "../../../../../../../../full-address-form"
import { useValidateAddressMutation } from "../../../../../../../../full-address-form/slice"
import { getAddressFormat, isRequiredField } from "../../../../../../../../../services/address-form"
import { isValidEmail, isValidPhoneNumber } from "../../../../../../../../quotations/components/rate-request/utils"
import {
  SoeDrawerActions,
  SoeDrawerContainer,
  SoeDrawerContent
} from "../../../../../../../../../soe-theme/src/components"
import { TRANSACTION_STATUS_ENUM } from "../../../../../../../../transaction-status"
import convertTZ from "../../../../../../../../../utils/convert-timezone"
import { useQuotationContext } from "../../../../../../../state/quotation-provider"

function RateRequestRecipient() {
  const { rateRequestStepWizard, selectedQuotation, setRecipient, setShowRateRequestForm } = useQuotationContext()

  const [action, setAction] = useState("")
  const [saveAddressChecked, setSaveAddressChecked] = useState(false)
  const [selectedAddressFromAutoComplete, setSelectedAddressFromAutoComplete] = useState({})
  const [validateAddress, { data: validationAddress, isLoading, isError }] = useValidateAddressMutation()
  const [showTransactionStatus, setShowTransactionStatus] = useState(false)

  const { t } = useTranslation("fullAddressForm")
  const { t: tOrderManagement } = useTranslation("orderManagement")

  const { data: countries } = useGetCountriesQuery()
  const { data: addressFormats } = useGetAddressFormatsQuery()
  const currentUser = useSelector((state) => state.user)

  const formik = useFormik({
    initialValues: {
      id: selectedQuotation?.recipient?.id || undefined,
      companyName: selectedQuotation?.recipient?.companyName || "",
      personName: selectedQuotation?.recipient?.personName || "",
      addressLine1: selectedQuotation?.recipient?.addressLine1 || "",
      addressLine2: selectedQuotation?.recipient?.addressLine2 || "",
      city: selectedQuotation?.recipient?.city || "",
      provinceName: selectedQuotation?.recipient?.provinceName || "",
      provinceCode: selectedQuotation?.recipient?.provinceCode || "",
      countryCode: selectedQuotation?.recipient?.countryCode || currentUser?.company?.clientAddress?.countryCode || currentUser?.clientAddress?.countryCode || "CA",
      postalCode: selectedQuotation?.recipient?.postalCode || "",
      residential: selectedQuotation?.recipient?.residential || false,
      phoneNumber: selectedQuotation?.recipient?.phoneNumber || "",
      emailAddress: selectedQuotation?.recipient?.emailAddress || "",
      timezone: selectedQuotation?.recipient?.timezone || undefined,
      validationProviderId: selectedQuotation?.recipient?.validationProviderId || undefined,
    },
    validate: async (formValues) => {
      const errors = {}

      if (!formValues.personName.trim()) {
        errors.personName = t("fields.personName.error.required")
      }

      const addressFormat = getAddressFormat({ addressFormats, countries, countryCode: formValues.countryCode })

      addressFormat?.forEach((fieldFormat) => {
        if (isRequiredField({ addressFormat, fieldName: fieldFormat.name }) && !formValues[fieldFormat.name]) {
          errors[fieldFormat.name] = t(`fields.${fieldFormat.name}.error.required`)
        }
      })

      if (!formValues.phoneNumber.trim()) {
        errors.phoneNumber = t("fields.phoneNumber.error.required")
      }

      const isPhoneNumberValid = isValidPhoneNumber(formValues.phoneNumber)
      if (formValues.phoneNumber && !isPhoneNumberValid) {
        errors.phoneNumber = t("fields.phoneNumber.error.invalid")
      }

      const isEmailValid = isValidEmail(formValues.emailAddress)

      if (formValues.emailAddress.trim() && !isEmailValid) {
        errors.emailAddress = t("fields.emailAddress.error.invalid")
      }

      return errors
    },
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (formValues) => {
      const { timezone, ...addressToValidate } = formValues
      setShowTransactionStatus(true)
      validateAddress(addressToValidate)
    },
  })

  useEffect(() => {
    if (validationAddress?.status === TRANSACTION_STATUS_ENUM.SUCCESS) {
      const temporaryRecipient = { ...formik.values, ...validationAddress?.metadata?.address }
      setRecipient(temporaryRecipient)
      // if (selectedQuotation?.shippingOptions && selectedQuotation?.shippingOptions.shippingDateTimezone !== Intl.DateTimeFormat().resolvedOptions()?.timeZone) {
      //   dispatch(updateShippingOptions({ ...shippingOptions, shippingDate: convertTZ(new Date(), temporaryShipper?.timezone).split(" ")[0], shippingDateTimezone: temporaryShipper?.timezone }))
      // }
      if ((selectedAddressFromAutoComplete && selectedAddressFromAutoComplete.origin === "CanadaPost") || (selectedAddressFromAutoComplete && selectedAddressFromAutoComplete.canadaPostId) || formik.values.canadaPostId) {
        if (selectedAddressFromAutoComplete.origin === "CanadaPost") formik.values = { ...formik.values, canadaPostId: selectedAddressFromAutoComplete.id }
        if (selectedAddressFromAutoComplete.canadaPostId) formik.values = { ...formik.values, canadaPostId: selectedAddressFromAutoComplete.canadaPostId }
        if (formik.values.canadaPostId) formik.values = { ...formik.values, canadaPostId: formik.values.canadaPostId }
      }

      // if (saveAddressChecked || saveDefaultAddressChecked) {
      //   if (saveDefaultAddressChecked) formik.values = { ...formik.values, isDefaultShipper: true }
      //   if (saveAddressChecked && !saveDefaultAddressChecked && formik.values?.id === currentUser.defaultShipperId) delete formik.values.id
      //   if (formik.values.id) {
      //     editAddressBookItem({ id: formik.values.id, payload: formik.values })
      //   } else {
      //     addAddressBookItem(formik.values)
      //   }
      // }
      if (action === "continue") {
        setShowTransactionStatus(false)
        rateRequestStepWizard.nextStep()
      }
      if (action === "saveAndClose") {
        setShowRateRequestForm(false)
        setShowTransactionStatus(false)
      }
    }
  }, [validationAddress])


  const getDisplaySaveAddressCheckbox = () => {
    return (formik.values?.id && formik.values.id !== currentUser.defaultShipperId && !formik.dirty)
  }

  const handleSaveAndCloseClick = () => {
    formik.handleSubmit()
    setAction("saveAndClose")
  }

  const handleContinueClick = () => {
    formik.handleSubmit()
    setAction("continue")
  }

  return (
    <SoeDrawerContainer>
      <SoeDrawerContent>
        <Typography variant="h5">{tOrderManagement("cardHeaders.recipient")}:</Typography>
        <FullAddressForm
          formik={formik}
          displaySearchAddressBar
          displayResidentialToggle
          displaySaveAddressCheckbox={false} // TODO: fix implementation
          displaySaveDefaultAddressCheckbox={false}
          setSelectedAddressFromAutoComplete={setSelectedAddressFromAutoComplete}
          saveAddressCheckboxLabel={formik.values.id ? t("recipient.updateAddressCheckbox.label") : t("recipient.saveAddressCheckbox.label")}
          saveAddressChecked={saveAddressChecked}
          setSaveAddressChecked={setSaveAddressChecked}
        />
      </SoeDrawerContent>
      {
        rateRequestStepWizard &&
        <SoeDrawerActions
          buttons={
            (rateRequestStepWizard.state.activeStep + 1) === Object.keys(rateRequestStepWizard.state.namedSteps).length
              ? [
                {
                  action: handleSaveAndCloseClick,
                  label: t("drawer.recipient.actions.prevButton.label", { ns: "rateRequest" }),
                  variant: "outlined",
                },
              ]
              : [
                {
                  action: handleSaveAndCloseClick,
                  label: t("drawer.recipient.actions.prevButton.label", { ns: "rateRequest" }),
                  variant: "outlined",
                },
                {
                  action: handleContinueClick,
                  label: t("drawer.recipient.actions.nextButton.label", { ns: "rateRequest" }),
                  variant: "contained",
                },
              ]
          }
        />
      }

    </SoeDrawerContainer>
  )
}

export default RateRequestRecipient