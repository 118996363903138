import restApiSlice from "../../services/rest-api/slice"

const printingQueueRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPendingShipments: builder.query({
      query: () => ({ url: "/orders/me/pending", method: "GET" }),
      transformResponse: (response) => response.data
    }),
  }),
})

export const { useGetPendingShipmentsQuery } = printingQueueRestApiSlice

export default printingQueueRestApiSlice.reducer
