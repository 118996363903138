// REACT
import React, { useEffect, useState } from "react"

import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import Alert from "@mui/material/Alert"
import Typography from "@mui/material/Typography"

// Formik
import { useFormik } from "formik"

// Theme
import { useTheme } from "@mui/material/styles"
import { useEditCompanyProfileMutation } from "../../../../../../../../../services/company/slice"
import { useValidateAddressMutation } from "../../../../../../../../full-address-form/slice"
import { useGetCountriesQuery } from "../../../../../../../../../services/address-form/slice"
import { useGetCarriersQuery } from "../../../../../../../../../services/carriers/slice"
import { useQuotationContext } from "../../../../../../../state/quotation-provider"
import {
  getThirdPartyBillingDetailsFromBillingForm,
  isFreightOptionsRequired,
  isInternationalShipment
} from "../../../../../../../../quotations/utils"
import { TRANSACTION_STATUS_ENUM } from "../../../../../../../../transaction-status"
import {
  CardContentNoPaddingBottom,
  SoeDrawerActions, SoeDrawerContainer,
  SoeDrawerContent
} from "../../../../../../../../../soe-theme/src/components"
import FullAddressForm from "../../../../../../../../full-address-form"
import getComparator from "../../../../../../../../../utils/sorting"
import { getRandId } from "../../../../../../../../../utils/generate-random-id"
import { pxToRem } from "../../../../../../../../../soe-theme/src/utils"

const SPACING = 10

const BILL_TO_TYPES = {
  myAccount: "myAccount",
  // thirdParty: "thirdParty", // TODO: enable after MVP
}

function RateRequestBillingOptions() {
  const { rateRequestStepWizard, selectedQuotation, setBillingOptions, setShowRateRequestForm } = useQuotationContext()
  const [selectedAddressFromAutoComplete, setSelectedAddressFromAutoComplete] = useState({})

  const { t, i18n } = useTranslation(["rateRequest", "constant"])
  const { t: tOrderManagement } = useTranslation("orderManagement")

  const customTheme = useTheme()
  const [editCompany] = useEditCompanyProfileMutation()

  const [, { data: validationAddress }] = useValidateAddressMutation()
  const { data: carriers } = useGetCarriersQuery()
  const { data: countries } = useGetCountriesQuery()

  const [action, setAction] = useState("")
  const currentUser = useSelector((state) => state.user)

  const supportedCarrierCodes = ["federal-express", "purolator-express", "purolator-freight", "fedex-freight", "dhl-express", "ups-parcel"]
  const supportedCarriers = carriers?.length > 0 ? carriers.filter((carrier) => supportedCarrierCodes.includes(carrier.code)) : []
  const savedAccounts = currentUser.company?.billing?.accountNumbers

  const formik = useFormik({
    initialValues: {
      type: selectedQuotation.billingOptions?.type || BILL_TO_TYPES.myAccount,
      name: selectedQuotation.billingOptions?.name || "",
      carrierCode: selectedQuotation.billingOptions?.carrierCode || "",
      thirdPartyShipperNumber: selectedQuotation.billingOptions?.thirdPartyShipperNumber || "",
      id: selectedQuotation.billingOptions?.id || "",
      companyName: selectedQuotation.billingOptions?.companyName || "",
      personName: selectedQuotation.billingOptions?.personName || "",
      addressLine1: selectedQuotation.billingOptions?.addressLine1 || "",
      addressLine2: selectedQuotation.billingOptions?.addressLine2 || "",
      city: selectedQuotation.billingOptions?.city || "",
      provinceName: selectedQuotation.billingOptions?.provinceName || "",
      provinceCode: selectedQuotation.billingOptions?.provinceCode || "",
      countryCode: selectedQuotation.billingOptions?.countryCode || "CA",
      postalCode: selectedQuotation.billingOptions?.postalCode || "",
      phoneNumber: selectedQuotation.billingOptions?.phoneNumber || "",
      emailAddress: selectedQuotation.billingOptions?.emailAddress || "",
      saveAccount: false,
    },
    validate: (formValues) => {
      const errors = {}

      if (formValues.type === BILL_TO_TYPES.myAccount) {
        return errors
      }

      if (!formValues.type) {
        errors.type = "Required"
      }

      if (!formValues.carrierCode) {
        errors.carrierCode = "Required"
      }

      if (!formValues.thirdPartyShipperNumber) {
        errors.thirdPartyShipperNumber = "Required"
      }

      if (!formValues.postalCode) {
        errors.postalCode = "Required"
      }

      if (!formValues.countryCode) {
        errors.countryCode = "Required"
      }

      if (!formValues.name) {
        errors.name = t("userProfile.thirdPartyBillingAccountsDrawer.fields.name.error.required")
      }

      if (formValues.carrierCode === "fedex-freight") {
        if (!formValues.companyName) errors.companyName = "Required"
        if (!formValues.addressLine1) errors.addressLine1 = "Required"
        if (!formValues.city) errors.city = "Required"
        if (!formValues.countryCode) errors.countryCode = "Required"
        if (!formValues.postalCode) errors.postalCode = "Required"
        if (!formValues.phoneNumber) errors.phoneNumber = "Required"
      }
      return errors
    },
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      setBillingOptions({ ...formValues })
      // save new third party account
      if (formValues.saveAccount) {
        let accountNumbers = savedAccounts?.length > 0 ? savedAccounts : []
        accountNumbers = accountNumbers.concat({ ...getThirdPartyBillingDetailsFromBillingForm(formValues), id: getRandId() })
        editCompany({ billing: { accountNumbers }, companyId: currentUser?.company?.id })
      }
      if (action === "continue") {
        if (isFreightOptionsRequired(selectedQuotation.packages)) {
          rateRequestStepWizard.goToNamedStep("freight-options")
          return
        }
        if (isInternationalShipment(selectedQuotation.shipper, selectedQuotation.recipient)) {
          rateRequestStepWizard.goToNamedStep("international-options")
          return
        }
      }
      if (action === "close") {
        setShowRateRequestForm(false)
      }
    },
  })

  useEffect(() => {
    if (!setShowRateRequestForm) formik.resetForm()
  }, [setShowRateRequestForm])

  useEffect(() => {
    if (validationAddress?.status === TRANSACTION_STATUS_ENUM.SUCCESS) {
      if ((selectedAddressFromAutoComplete && selectedAddressFromAutoComplete.origin === "CanadaPost") || (selectedAddressFromAutoComplete && selectedAddressFromAutoComplete.canadaPostId) || formik.values.canadaPostId) {
        if (selectedAddressFromAutoComplete.origin === "CanadaPost") formik.values = { ...formik.values, canadaPostId: selectedAddressFromAutoComplete.id }
        if (selectedAddressFromAutoComplete.canadaPostId) formik.values = { ...formik.values, canadaPostId: selectedAddressFromAutoComplete.canadaPostId }
        if (formik.values.canadaPostId) formik.values = { ...formik.values, canadaPostId: formik.values.canadaPostId }
      }
    }
  }, [validationAddress])

  const handleSaveAndCloseClick = () => {
    setAction("close")
    formik.handleSubmit()
  }

  const handleContinueClick = () => {
    setAction("continue")
    formik.handleSubmit()
  }

  const handleSelectSavedAccount = (e) => {
    const { value: savedCardId } = e.target
    const accountToPrefill = savedAccounts.find((acc) => acc.id === savedCardId)
    formik.setValues({ type: formik.values.type, ...accountToPrefill, saveAccount: false }, [])
  }

  const handleMyAccountSelect = (e) => {
    const { value: billToType } = e.target
    // Reset preselected values
    if (billToType === BILL_TO_TYPES.myAccount) {
      formik.setValues(
        {
          type: billToType,
          companyName: "",
          personName: "",
          addressLine1: "",
          addressLine2: "",
          city: "",
          provinceName: "",
          provinceCode: "",
          countryCode: "CA",
          postalCode: "",
          phoneNumber: "",
          emailAddress: "",
          name: "",
          carrierCode: "",
          thirdPartyShipperNumber: "",
          id: "",
          saveAccount: false,
        },
        []
      )
    } else {
      formik.handleChange(e)
    }
  }
  const isThirdPartyPayment = formik.values.type === BILL_TO_TYPES.thirdParty

  const shouldDisableSaveButton =
    (Object.entries(formik.errors).length > 0) || !!savedAccounts?.find((a) => a.thirdPartyShipperNumber === formik.values.thirdPartyShipperNumber && a.postalCode === formik.values.postalCode && a.carrierCode === formik.values.carrierCode && a.name === formik.values.name && a.countryCode === formik.values.countryCode)

  return (
    <SoeDrawerContainer>
      <SoeDrawerContent>
        <Box style={{marginBottom: pxToRem(15)}}>
          <Typography variant="h5">{tOrderManagement("cardHeaders.billingOptions")}:</Typography>
        </Box>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Card>
            <CardContentNoPaddingBottom
              sx={{
                "&:last-child": {
                  pb: 2,
                },
              }}
            >
              <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
                <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <InputLabel id="type">{t("drawer.billingOptions.fields.modes.label", { ns: "rateRequest" })}</InputLabel>
                    <Select labelId="type" id="type" name="type" label="Bill too" size="small" onChange={handleMyAccountSelect} value={formik.values.type}>
                      {/* {[BILL_TO_TYPES.myAccount, BILL_TO_TYPES.thirdParty].map((mode, index) => ( // TODO: enable after MVP */}
                      {[BILL_TO_TYPES.myAccount].map((mode, index) => (
                        <MenuItem key={mode.concat(index)} value={mode}>
                          {t(`drawer.billingOptions.fields.modes.options.${mode}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                {isThirdPartyPayment && (
                  <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
                    <FormControl variant="outlined" size="small" fullWidth disabled={!savedAccounts?.length || !isThirdPartyPayment}>
                      <InputLabel id="id">{t("drawer.billingOptions.fields.savedAccounts.label", { ns: "rateRequest" })}</InputLabel>
                      <Select labelId="id" id="id" name="id" label={t("drawer.billingOptions.fields.savedAccounts.label", { ns: "rateRequest" })} size="small" onChange={handleSelectSavedAccount} value={formik.values.id}>
                        {savedAccounts?.map((account) => (
                          <MenuItem key={account.id} value={account.id}>
                            {account.name ? `${account.name} - ${account.carrierCode}` : account.carrierCode}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                )}
              </Box>
            </CardContentNoPaddingBottom>
          </Card>

          {isThirdPartyPayment && (
            <>
              {/* Add new account number component */}
              <Card style={{ margin: "20px 0" }}>
                <CardContentNoPaddingBottom
                  sx={{
                    "&:last-child": {
                      pb: 2,
                    },
                  }}
                >
                  <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
                    {supportedCarriers && (
                      <FormControl variant="outlined" size="small" fullWidth disabled={!isThirdPartyPayment}>
                        <InputLabel id="carrierCode">{t("drawer.billingOptions.fields.carrierCode.label")}</InputLabel>
                        <Select
                          labelId="carrierCode"
                          id="carrierCode"
                          name="carrierCode"
                          label={t("drawer.billingOptions.fields.carrierCode.label")}
                          onChange={formik.handleChange}
                          value={formik.values.carrierCode || ""}
                          error={formik.touched.carrierCode && formik.errors.carrierCode !== undefined}
                          SelectDisplayProps={{ style: { height: 0 } }}
                        >
                          {supportedCarriers.sort(getComparator("asc", "fullName.en")).map((carrier) => {
                            return <MenuItem key={carrier.code} value={carrier.code}>
                              {carrier.fullName.en} {/* always show english carrier names */}
                            </MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    )}
                  </Box>

                  <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
                    <TextField
                      id="thirdPartyShipperNumber"
                      label={t("drawer.billingOptions.fields.thirdPartyShipperNumber.label")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.thirdPartyShipperNumber || ""}
                      error={formik.touched.thirdPartyShipperNumber && formik.errors.thirdPartyShipperNumber !== undefined}
                      disabled={!isThirdPartyPayment}
                      type="text"
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Box>
                  {formik.values.carrierCode !== "fedex-freight" && (
                    <>
                      <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
                        <TextField
                          id="postalCode"
                          label={t("drawer.billingOptions.fields.postalCode.label")}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.postalCode || ""}
                          error={formik.touched.postalCode && formik.errors.postalCode !== undefined}
                          disabled={!isThirdPartyPayment}
                          type="text"
                          variant="outlined"
                          fullWidth
                          size="small"
                        />
                      </Box>

                      <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
                        {countries && (
                          <FormControl variant="outlined" size="small" fullWidth disabled={!isThirdPartyPayment}>
                            <InputLabel id="countryCode">{t("drawer.billingOptions.fields.countryCode.label")}</InputLabel>
                            <Select
                              labelId="countryCode"
                              id="countryCode"
                              name="countryCode"
                              label={t("drawer.billingOptions.fields.countryCode.label")}
                              error={formik.touched.countryCode && formik.errors.countryCode !== undefined}
                              onChange={formik.handleChange}
                              value={formik.values.countryCode}
                              SelectDisplayProps={{ style: { height: 0 } }}
                            >
                              {countries.map((country) => (
                                <MenuItem key={country.code} value={country.code}>
                                  {country.name[i18n.language]}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Box>
                    </>
                  )}

                  <Box component="div" my={customTheme.utils.pxToThemeSpacing(SPACING)}>
                    <TextField
                      id="name"
                      label={t("drawer.billingOptions.fields.name.label")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name || ""}
                      disabled={!isThirdPartyPayment}
                      error={formik.touched.name && formik.errors.name !== undefined}
                      type="text"
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Box>

                  {formik.values.carrierCode === "fedex-freight" && (
                    <>
                      <Alert icon={false} severity="info" sx={(theme) => ({ pl: theme.spacing(2), pr: theme.spacing(1) })}>
                        <Typography component="p" variant="bodyMedium400">
                          {t("drawer.billingOptions.alerts.addressMatch", { ns: "rateRequest" })}
                        </Typography>
                      </Alert>
                      <FullAddressForm formik={formik} displayResidentialToggle={false} displaySaveAddressCheckbox={false} displaySearchAddressBar setSelectedAddressFromAutoComplete={setSelectedAddressFromAutoComplete} saveDefaultAddressDisabled />
                    </>
                  )}
                </CardContentNoPaddingBottom>
              </Card>
              <FormControlLabel
                style={{ marginLeft: 10 }}
                disabled={(savedAccounts?.length > 0 && shouldDisableSaveButton)}
                control={<Checkbox name="saveAccount" checked={formik.values.saveAccount || false} onChange={formik.handleChange} />}
                label="Save this third-party account for future use"
              />
            </>)
          }
        </form>
      </SoeDrawerContent>
      <SoeDrawerActions
        buttons={
          isInternationalShipment(selectedQuotation.shipper, selectedQuotation.recipient) || isFreightOptionsRequired(selectedQuotation.packages)
            ? [
              {
                action: handleSaveAndCloseClick,
                label: t("drawer.packages.actions.prevButton.label", { ns: "rateRequest" }),
                variant: "outlined",
              },
              {
                action: handleContinueClick,
                label: t("drawer.packages.actions.nextButton.label", { ns: "rateRequest" }),
                variant: "contained",
              },
            ]
            : [
              {
                action: handleSaveAndCloseClick,
                label: t("drawer.packages.actions.prevButton.label", { ns: "rateRequest" }),
                variant: "outlined",
              },
            ]
        }
      />
    </SoeDrawerContainer>
  )
}

export default RateRequestBillingOptions
