import React from 'react';
import { CardMedia } from "@mui/material"
import airplaneAnimation from "../../../../../../../assets/videos/airplane-animation.mp4"
import { pxToRem } from "../../../../../../../soe-theme/src/utils"


function LoadingVideo() {

  return (
    <CardMedia
      component="video"
      autoPlay
      loop
      muted
      src={ airplaneAnimation }
      style={{ width: "100%", overflowY: "hidden" }}
    />
  )
}

export default LoadingVideo
