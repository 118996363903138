import restApiSlice from "../../../../../../services/rest-api/slice"

export const paymentValidationRestApiSlice = restApiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getValidation: builder.query({
      query: (rateResponseId) => ({ url: `/v2/carriers/generic/validate/${rateResponseId}`, method: "GET" }),
      transformResponse: (response) => response.data,
      extraOptions: {
        target: "paymentValidation",
      },
      keepUnusedDataFor: 0,
    }),
    validateMany: builder.query({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const validationResults = await Promise.all(
          _arg.map(async (rateResponseId) => {
            const result = await fetchWithBQ({
              url: `/v2/carriers/generic/validate/${rateResponseId}`,
              method: "GET"
            });
            return {
              ...result,
              rateResponseId
            };
          })
        );

        return {
          data: validationResults.map((result) => ({
            ...result.data,
            rateResponseId: result.rateResponseId
          })),
        };
      },
    }),
  }),
})

export const { useGetValidationQuery, useValidateManyQuery } = paymentValidationRestApiSlice

export default paymentValidationRestApiSlice.reducer
