import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import ModalButtonsRow from "../../../../../../../generic/components/modal-buttons-row"
import useOrderValidations from "../../../../../../../../../hooks/validate-quotes"
import { useQuotationContext } from "../../../../../../../../../state/quotation-provider"
import { useValidationContext } from "../../../../../../state/provider"
import { useQuotationTableContext } from "../../../../../../../../../state/quotations-table-provider"

function ButtonsRow() {
  const { validationFlowWizard } = useQuotationContext()
  const { selectedQuotationsWithQuotes } = useQuotationTableContext()
  const [shouldValidate, setShouldValidate] = useState(false);
  const { data, isLoading: isValidationLoading } = useOrderValidations(selectedQuotationsWithQuotes, shouldValidate);
  const { setValidatedQuotations, setInvalidQuotations, closeValidationModal, setModalAlert } = useValidationContext()
  const { t } = useTranslation("orderManagement")

  const handleValidate = () => {
    setShouldValidate(true);
  }

  const handleCancel = () => {
    closeValidationModal();
  }

  const filterValidatedQuotations = (validationResults) => {
    // Create a Set of rateResponseIds that have status "success"
    const successfulIds = new Set(
      validationResults
        .filter(result => result.data?.status === "success")
        .map(result => result.rateResponseId)
    );

    const [validQuotations, invalidQuotations] = selectedQuotationsWithQuotes.reduce((acc, quotation) => {
        if (successfulIds.has(quotation.quote.rateResponseId)) {
          acc[0].push(quotation);
        } else {
          const validationErrorMessages = validationResults
            .find(result => result.rateResponseId === quotation.quote.rateResponseId)
            ?.data?.messages
            .map(msg => msg.message);
          acc[1].push({ ...quotation,
            quote: {
              ...quotation.quote,
              validationErrorMessages,
          } });
        }
        return acc;
      }, [[], []]);

    if (invalidQuotations.length > 0) {
      setModalAlert({
        severity: 'error',
        message: t("alert.error.validate.label")
      });
    }

    setValidatedQuotations(validQuotations);
    setInvalidQuotations(invalidQuotations);
  }


  // TODO: fix bug where once I validate the quotes once, if I close and reopen the Modal it skips the API validation call when I press the validate button
  // possibly related to the state of "shouldValidate"
  useEffect(() => {
    if (!shouldValidate) return
    filterValidatedQuotations(data)
    validationFlowWizard.goToNamedStep("validation-results")
  }, [data]);

  return (
    <ModalButtonsRow onLeftButtonClick={handleValidate} onRightButtonClick={handleCancel} isLoading={isValidationLoading} leftButtonLabel={t("modals.validation.buttons.validate.label")} rightButtonLabel={t("modals.validation.buttons.cancel.label")} />
  )
}

export default ButtonsRow
