import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import RateRequestSummaryCard from "../rate-request-summary-card"

import { BrokerProps } from "../../models/rate-request"
import { isBrokerComplete } from "../quotations/utils"

function BrokerCard({
    broker,
    setShowRateRequestDrawer,
    disabled,
    isEditable,
    showPhoneNumber,
    showEmailAddress,
    isValid: customIsBrokerComplete,
    ...sw
})
{
  const theme = useTheme()
  const { t } = useTranslation("quotations")

  const showRateRequestDrawer = () => {
    sw.stepWizard.goToNamedStep("broker")
    setShowRateRequestDrawer(true)
  }

  const summaryStatusProps = {
    variant: customIsBrokerComplete !== undefined
      ? (customIsBrokerComplete ? "success" : "warning")
      : (isBrokerComplete(broker) ? "success" : "warning")
  }

  return (
    !disabled && (
      <RateRequestSummaryCard title={t("brokerTitle")} handleClick={showRateRequestDrawer} disabled={disabled} summaryStatusProps={summaryStatusProps} showStatus={isEditable} isClickable={isEditable}>
        {broker && Object.keys(broker).length > 0 && (
          <Box
            mt={1}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {broker.companyName && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {broker.companyName}
              </Typography>
            )}
            {broker.personName && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {broker.personName}
              </Typography>
            )}
            {broker.addressLine1 && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {broker.addressLine1}
              </Typography>
            )}
            {broker.addressLine2 && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {broker.addressLine2}
              </Typography>
            )}
            {(broker.city || broker.provinceCode) && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {`${broker.city || ""}${broker.city && broker.provinceCode ? ", " : ""}${broker.provinceCode || ""}${(broker.city || broker.provinceCode) && broker.countryCode ? ", " : ""}${broker.countryCode || ""}`}
              </Typography>
            )}
            {broker.postalCode && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {broker.postalCode}
              </Typography>
            )}
            {(showPhoneNumber || showEmailAddress) && (
              <Box
                mt={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {showPhoneNumber && broker.phoneNumber && (
                  <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                    {broker.phoneNumber}
                  </Typography>
                )}
                {showEmailAddress && broker.emailAddress && (
                  <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                    {broker.emailAddress}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        )}
      </RateRequestSummaryCard>
    )
  )
}

BrokerCard.propTypes = {
  broker: BrokerProps,
  setShowRateRequestDrawer: PropTypes.func,
  disabled: PropTypes.bool,
  isEditable: PropTypes.bool,
  showPhoneNumber: PropTypes.bool,
  showEmailAddress: PropTypes.bool,
  isValid: PropTypes.bool,
}

BrokerCard.defaultProps = {
  broker: undefined,
  setShowRateRequestDrawer: () => {},
  disabled: false,
  isEditable: true,
  showPhoneNumber: false,
  showEmailAddress: false,
  isValid: undefined,
}

export default BrokerCard
