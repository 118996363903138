import React, { useEffect } from "react"
import { Box, Modal, Paper } from "@mui/material"
import { pxToRem } from "../../../../../soe-theme/src/utils"
import { useQuotationContext } from "../../../state/quotation-provider"
import ValidationStepWizard from "./components/validation-step-wizard"
import ValidationProvider from "./state/provider"
import ModalTitle from "./components/modal-title"
import AlertContainer from "./components/alert-container"
import { useQuotationTableContext } from "../../../state/quotations-table-provider"


function ValidateCreateLabelModal() {
  const { showValidateCreateLabelModal, setShowValidateCreateLabelModal } = useQuotationContext()
  const { selectedQuotationsWithQuotes } = useQuotationTableContext()


  const closeModal = () => {
    setShowValidateCreateLabelModal(false)
  }

  useEffect(() => {
    if (selectedQuotationsWithQuotes.length === 0) closeModal()
  }, [selectedQuotationsWithQuotes])

  // TODO: add Terms and Conditions
  return (
    <ValidationProvider>
      <Modal open={showValidateCreateLabelModal} disableEscapeKeyDown sx={{display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Paper elevation={2} sx={{ overflowY:"hidden", height: "80%", width: "70%", display: "flex", flexDirection: "column", borderRadius: `${pxToRem(5)}`}}>
          <Box style={{width:"100%", display: "flex", justifyContent: "center", position:"sticky", top:0, backgroundColor: "transparent", zIndex: 1, padding: `${pxToRem(10)}`, borderBottomWidth: `${pxToRem(1)}`, borderBottomColor: "black"}}>
            <ModalTitle />
          </Box>
          <AlertContainer />
          <Box style={{width: "100%", height:"100%", overflow: "hidden"}}>
            <ValidationStepWizard />
          </Box>
        </Paper>
      </Modal>
    </ValidationProvider>
  )


}


export default ValidateCreateLabelModal
