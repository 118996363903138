import React from "react"
import PropTypes from "prop-types"
import CircularProgress from "@mui/material/CircularProgress"
import { Box, Tooltip } from "@mui/material"
import QuoteButton from "../quote-button"
import CreateLabelButton from "../create-label-button"
import { pxToRem } from "../../../../../../../../soe-theme/src/utils"
import SoeErrorOutlineIcon from "../../../../../../../../soe-theme/src/icons/SoeErrorOutlineIcon"
import useGetQuote from "../../../../../../hooks/get-quote"
import { useQuotationContext } from "../../../../../../state/quotation-provider"
import { useQuotationTableContext } from "../../../../../../state/quotations-table-provider"

function ActionButtonsContainer({ order, isOrderComplete, isQuotationValidationLoading, errors }) {
  const { obtainQuote, error: isGettingQuoteError } = useGetQuote();
  const { setShowValidateCreateLabelModal } = useQuotationContext()
  const { updatedSelectedQuotations, clearSelectedQuotations } = useQuotationTableContext()



  const isGettingQuoteLoading = order.loading?.quote || false
  const isValidateCreateLabelLoading = order.loading?.validateCreateLabel || false
  const showLoading = isQuotationValidationLoading || isGettingQuoteLoading || isValidateCreateLabelLoading
  const isQuoted = order.quote !== undefined

  const handleQuoteClick = async (event) => {
    event.stopPropagation();
    await obtainQuote(order)
  }

  const handleValidateClick = async (event) => {
    event.stopPropagation()
    clearSelectedQuotations()
    updatedSelectedQuotations(order)
    setShowValidateCreateLabelModal(true)
  }

  const errorMessages = Object.values(errors)
  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: pxToRem(3), minHeight: pxToRem(90), minWidth: pxToRem(110) }}>
      {
        !isOrderComplete
        ?
          <Tooltip title={errorMessages[0] || ""} placement="left-start" >
            <SoeErrorOutlineIcon color="error" />
          </Tooltip>
        :
          showLoading ?
            <CircularProgress size={pxToRem(20)} sx={(theme) => ({ color: theme.palette.secondary.main })} />
          :
          <>
            <QuoteButton onClick={handleQuoteClick} isQuoted={isQuoted} />
            {isQuoted && <CreateLabelButton onClick={handleValidateClick} />}
          </>
      }
    </Box>
  )
}

ActionButtonsContainer.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
  isOrderComplete: PropTypes.bool.isRequired,
  isQuotationValidationLoading: PropTypes.bool.isRequired,
  errors: PropTypes.instanceOf(Object),
}

ActionButtonsContainer.defaultProps = {
  errors: {},
}

export default ActionButtonsContainer
