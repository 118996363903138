import React, { createContext, useContext, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useQuotationContext } from "../../../../../state/quotation-provider"
import { useQuotationTableContext } from "../../../../../state/quotations-table-provider"

const ValidationContext = createContext()

function ValidationProvider({ children }) {
  const { setValidationFlowWizard, setShowValidateCreateLabelModal} = useQuotationContext()
  const [validatedQuotations, setValidatedQuotations] = useState([])
  const [invalidQuotations, setInvalidQuotations] = useState([])
  const [successfullyPaidQuotations, setSuccessfullyPaidQuotations] = useState([])
  const [unsuccessfullyPaidQuotations, setUnsuccessfullyPaidQuotations] = useState([])
  const [modalAlert, setModalAlert] = useState()
  const [isPaymentLoading, setIsPaymentLoading] = useState(false)

  const clearModalAlert = () => {
    setModalAlert(undefined)
  }

  const removeValidatedQuotation = (quotation) => {
    setValidatedQuotations((prevValidatedQuotations) =>
      prevValidatedQuotations.filter(validQuotation => validQuotation.rateRequestId !== quotation.rateRequestId)
    )
  }

  const closeValidationModal = () => {
    clearModalAlert()
    setShowValidateCreateLabelModal(false)
    setValidationFlowWizard(undefined)


  }

  const value = useMemo(
    () => ({
      validatedQuotations,
      setValidatedQuotations,
      invalidQuotations,
      setInvalidQuotations,
      modalAlert,
      clearModalAlert,
      isPaymentLoading,
      setIsPaymentLoading,
      successfullyPaidQuotations,
      setSuccessfullyPaidQuotations,
      unsuccessfullyPaidQuotations,
      setUnsuccessfullyPaidQuotations,
      removeValidatedQuotation,
      setModalAlert,
      closeValidationModal,
    }),
    [ validatedQuotations, invalidQuotations, modalAlert, isPaymentLoading, successfullyPaidQuotations, unsuccessfullyPaidQuotations]
  )

  return (
    <ValidationContext.Provider value={value}>
      {children}
    </ValidationContext.Provider>
  )
}

ValidationProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useValidationContext = () => {
  return useContext(ValidationContext)
}

export default ValidationProvider
