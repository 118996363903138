export function isAnySelectedQuotationsQuoted(quotations) {
  return quotations.some(quotation => quotation.quote !== undefined);
}

export function isAllSelectedQuotationsCompleted(quotations) {
  if (quotations.length === 0) return false
  for (let i = 0; i < quotations.length; i += 1) {
    if (quotations[i].isComplete !== true) {
      return false;
    }
  }
  return true;
}