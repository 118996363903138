import React from "react"
import { Grid } from "@mui/material"
import PropTypes from "prop-types"

function SummaryCardGrid({ children }) {
  return (
    <Grid item xs={12} md={6}>
      {children}
    </Grid>
  )
}

SummaryCardGrid.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SummaryCardGrid
