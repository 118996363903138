import React from "react"
import PropTypes from "prop-types"
import AddressSummary from "../../../../../../address-summary"

function ShipperInfo({ shipper, shipperComplete }) {
  if (!shipper || !shipperComplete) return null

  return (
    <AddressSummary addressSummary={shipper} />
  )
}

ShipperInfo.propTypes = {
  shipper: PropTypes.instanceOf(Object),
  shipperComplete: PropTypes.bool.isRequired,
}

ShipperInfo.defaultProps = {
  shipper: undefined,
}

export default ShipperInfo
