// React
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Outlet, useNavigate } from "react-router-dom"

// MUI
import Modal from "@mui/material/Modal"
import { useTranslation } from "react-i18next"
import { CustomModalContainerBox, CustomOutletBox, CustomPageBox, CustomContainer } from "./style"

import AppConfig from "../../utils/app-config"
import CustomAlert from "../alert" // @todo: we should not use the same name of MUI component for custum component (reserve words)
import InexAppBar from "../inex-app-bar"
import AccountConfiguration from "../account-configuration"

import { clearLastLocation, cognitoSignIn, setShowAuthenticator, useGetTokenQuery } from "../../reducers/authenticatorReducer"

function App() {
  const { i18n } = useTranslation()
  const { lastLocation } = useSelector((state) => state.authenticator)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [code, setCode] = useState()
  const [showAccountConfiguration, setAccountConfiguration] = useState(false)

  const currentUser = useSelector((state) => state.user)

  const { data: tokenData } = useGetTokenQuery(code, { skip: !code })

  useEffect(() => {
    const windowUrl = window.location.search
    const params = new URLSearchParams(windowUrl)
    const googleCode = params.get("code")
    if (googleCode) dispatch(setShowAuthenticator(false))
    setCode(googleCode)
  }, [])

  useEffect(() => {
    if (tokenData) {
      dispatch(cognitoSignIn(tokenData))
      setCode(undefined)
      navigate(lastLocation || "/", { replace: true })
      dispatch(clearLastLocation())
    }
  }, [tokenData])

  useEffect(() => {
    if (currentUser?.defaultLanguage) i18n.changeLanguage(currentUser?.defaultLanguage)
  }, [currentUser])

  useEffect(() => {
    if (AppConfig.app.environment?.toLowerCase() !== "prod") document.title = `[${AppConfig.app.environment}] Inex`
  }, [])

  return (
    <CustomContainer maxWidth={false} disableGutters fixed={false} id="container-root">
      <CustomPageBox component="div">
        <InexAppBar setAccountConfiguration={setAccountConfiguration} setShowAuthenticator={setShowAuthenticator} />
        <CustomAlert />
        <CustomOutletBox component="div" style={{ position: "relative", overflowX: "hidden" }}>
          <Outlet />
        </CustomOutletBox>
        {/* @todo, do we want a generic footer here */}
      </CustomPageBox>
      <Modal open={showAccountConfiguration}>
        <CustomModalContainerBox className="authContainer" component="div">
          <AccountConfiguration setAccountConfiguration={setAccountConfiguration} />
        </CustomModalContainerBox>
      </Modal>
    </CustomContainer>
  )
}

export default App
