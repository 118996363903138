import React, { useEffect, useState } from "react"
import { Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import { useGetAddressFormatsQuery, useGetCountriesQuery } from "../../../../../../../../../services/address-form/slice"
import FullAddressForm from "../../../../../../../../full-address-form"
import { useValidateAddressMutation } from "../../../../../../../../full-address-form/slice"
import { getAddressFormat, isRequiredField } from "../../../../../../../../../services/address-form"
import { isValidEmail, isValidPhoneNumber } from "../../../../../../../../quotations/components/rate-request/utils"
import {
  SoeDrawerActions,
  SoeDrawerContainer, SoeDrawerContent
} from "../../../../../../../../../soe-theme/src/components"
import { TRANSACTION_STATUS_ENUM } from "../../../../../../../../transaction-status"
import convertTZ from "../../../../../../../../../utils/convert-timezone"
import { useQuotationContext } from "../../../../../../../state/quotation-provider"
import { useAddAddressBookItemMutation, useEditAddressBookItemMutation } from "../../../../../../../../address-book/slice"

function RateRequestShipper() {
  const { rateRequestStepWizard, selectedQuotation, setShipper, setShowRateRequestForm, setShippingOptions } = useQuotationContext()

  const [action, setAction] = useState("")
  const [saveAddressChecked, setSaveAddressChecked] = useState(false)
  const [selectedAddressFromAutoComplete, setSelectedAddressFromAutoComplete] = useState({})
  const [validateAddress, { data: validationAddress, isLoading, isError }] = useValidateAddressMutation()
  const [showTransactionStatus, setShowTransactionStatus] = useState(false)

  const { t } = useTranslation("fullAddressForm")
  const { t: tOrderManagement } = useTranslation("orderManagement")

  const [addAddressBookItem, { data: newAddressBookItem }] = useAddAddressBookItemMutation()
  const [editAddressBookItem, { data: updatedAddressBookItem }] = useEditAddressBookItemMutation()

  const { data: countries } = useGetCountriesQuery()
  const { data: addressFormats } = useGetAddressFormatsQuery()
  const currentUser = useSelector((state) => state.user)

  const formik = useFormik({
    initialValues: {
      id: selectedQuotation?.shipper?.id || undefined,
      companyName: selectedQuotation?.shipper?.companyName || "",
      personName: selectedQuotation?.shipper?.personName || "",
      addressLine1: selectedQuotation?.shipper?.addressLine1 || "",
      addressLine2: selectedQuotation?.shipper?.addressLine2 || "",
      city: selectedQuotation?.shipper?.city || "",
      provinceName: selectedQuotation?.shipper?.provinceName || "",
      provinceCode: selectedQuotation?.shipper?.provinceCode || "",
      countryCode: selectedQuotation?.shipper?.countryCode || currentUser?.company?.clientAddress?.countryCode || currentUser?.clientAddress?.countryCode || "CA",
      postalCode: selectedQuotation?.shipper?.postalCode || "",
      residential: selectedQuotation?.shipper?.residential || false,
      phoneNumber: selectedQuotation?.shipper?.phoneNumber || "",
      emailAddress: selectedQuotation?.shipper?.emailAddress || "",
      timezone: selectedQuotation?.shipper?.timezone || undefined,
      validationProviderId: selectedQuotation?.shipper?.validationProviderId || undefined,
    },
    validate: async (formValues) => {
      const errors = {}

      if (!formValues.personName.trim()) {
        errors.personName = t("fields.personName.error.required")
      }

      const addressFormat = getAddressFormat({ addressFormats, countries, countryCode: formValues.countryCode })

      addressFormat?.forEach((fieldFormat) => {
        if (isRequiredField({ addressFormat, fieldName: fieldFormat.name }) && !formValues[fieldFormat.name]) {
          errors[fieldFormat.name] = t(`fields.${fieldFormat.name}.error.required`)
        }
      })

      if (!formValues.phoneNumber.trim()) {
        errors.phoneNumber = t("fields.phoneNumber.error.required")
      }

      const isPhoneNumberValid = isValidPhoneNumber(formValues.phoneNumber)
      if (formValues.phoneNumber && !isPhoneNumberValid) {
        errors.phoneNumber = t("fields.phoneNumber.error.invalid")
      }

      const isEmailValid = isValidEmail(formValues.emailAddress)

      if (formValues.emailAddress && !isEmailValid) {
        errors.emailAddress = t("fields.emailAddress.error.invalid")
      }

      return errors
    },
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (formValues) => {
      const { timezone, ...addressToValidate } = formValues
      setShowTransactionStatus(true)
      validateAddress(addressToValidate)
    },
  })

  useEffect(() => {
    if (validationAddress?.status === TRANSACTION_STATUS_ENUM.SUCCESS) {
      const temporaryShipper = { ...formik.values, ...validationAddress?.metadata?.address }
      setShipper(temporaryShipper)
      // if (selectedQuotation?.shippingOptions && selectedQuotation?.shippingOptions.shippingDateTimezone !== Intl.DateTimeFormat().resolvedOptions()?.timeZone) {
      //   setShippingOptions({ ...selectedQuotation?.shippingOptions, shippingDate: convertTZ(new Date(), temporaryShipper?.timezone).split(" ")[0], shippingDateTimezone: temporaryShipper?.timezone })
      // }
      if ((selectedAddressFromAutoComplete && selectedAddressFromAutoComplete.origin === "CanadaPost") || (selectedAddressFromAutoComplete && selectedAddressFromAutoComplete.canadaPostId) || formik.values.canadaPostId) {
        if (selectedAddressFromAutoComplete.origin === "CanadaPost") formik.values = { ...formik.values, canadaPostId: selectedAddressFromAutoComplete.id }
        if (selectedAddressFromAutoComplete.canadaPostId) formik.values = { ...formik.values, canadaPostId: selectedAddressFromAutoComplete.canadaPostId }
        if (formik.values.canadaPostId) formik.values = { ...formik.values, canadaPostId: formik.values.canadaPostId }
      }

      // if (saveAddressChecked) {
      //   if (saveAddressChecked && formik.values?.id === currentUser.defaultShipperId) delete formik.values.id
      //   if (formik.values.id) {
      //     console.log('HERE')
      //     editAddressBookItem({ id: formik.values.id, payload: formik.values })
      //   } else {
      //     addAddressBookItem(formik.values)
      //   }
      // }

      if (action === "continue") {
        setShowTransactionStatus(false)
        rateRequestStepWizard.nextStep()
      }
      if (action === "saveAndClose") {
        setShowRateRequestForm(false)
        setShowTransactionStatus(false)
      }
    }
  }, [validationAddress])


  const getDisplaySaveAddressCheckbox = () => {
    return (formik.values?.id && formik.values.id !== currentUser.defaultShipperId && !formik.dirty)
  }

  const handleSaveAndCloseClick = () => {
    formik.handleSubmit()
    setAction("saveAndClose")
  }

  const handleContinueClick = () => {
    formik.handleSubmit()
    setAction("continue")
  }

  return (
    <SoeDrawerContainer>
      <SoeDrawerContent>
        <Typography variant="h5">{tOrderManagement("cardHeaders.shipper")}:</Typography>
        <FullAddressForm
          formik={formik}
          displaySearchAddressBar
          displayResidentialToggle
          // displaySaveAddressCheckbox={getDisplaySaveAddressCheckbox()}
          displaySaveAddressCheckbox={false} // TODO: fix implementation
          displaySaveDefaultAddressCheckbox={false}
          saveAddressCheckboxLabel={formik.values.id && formik.values.id !== currentUser.defaultShipperId ? t("shipper.updateAddressCheckbox.label") : t("shipper.saveAddressCheckbox.label")}
          saveDefaultAddressCheckboxLabel={formik.values.id && formik.values.id === currentUser.defaultShipperId ? t("shipper.updateDefaultAddressCheckbox.label") : t("shipper.saveDefaultAddressCheckbox.label")}
          setSelectedAddressFromAutoComplete={setSelectedAddressFromAutoComplete}
          saveAddressChecked={saveAddressChecked}
          setSaveAddressChecked={setSaveAddressChecked}
          saveDefaultAddressDisabled
        />
      </SoeDrawerContent>
      {
        rateRequestStepWizard &&
        <SoeDrawerActions
          buttons={
            (rateRequestStepWizard.state.activeStep + 1) === Object.keys(rateRequestStepWizard.state.namedSteps).length
              ? [
                {
                  action: handleSaveAndCloseClick,
                  label: t("drawer.shipper.actions.prevButton.label", { ns: "rateRequest" }),
                  variant: "outlined",
                },
              ]
              : [
                {
                  action: handleSaveAndCloseClick,
                  label: t("drawer.shipper.actions.prevButton.label", { ns: "rateRequest" }),
                  variant: "outlined",
                },
                {
                  action: handleContinueClick,
                  label: t("drawer.shipper.actions.nextButton.label", { ns: "rateRequest" }),
                  variant: "contained",
                },
              ]
          }
        />
      }

    </SoeDrawerContainer>
  )
}

export default RateRequestShipper