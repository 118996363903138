function aggregateQuoteRates(quotes) {
  return quotes.reduce((ratesByCurrency, quote) => {
    const { amount, currency } = quote.rate.totals.discounted.total;
    if (!ratesByCurrency[currency]) {
      ratesByCurrency[currency] = { amount: 0, currency };
    }
    ratesByCurrency[currency].amount += amount;
    return ratesByCurrency;
  }, {});
}

export default aggregateQuoteRates
