import React from "react"
import { Typography, TableRow, TableCell, Box } from "@mui/material"
import PropTypes from "prop-types"
import Currency from "../../../../../../../../currency"
import SoeCancelIcon from "../../../../../../../../../soe-theme/src/icons/SoeCancelIcon"
import { pxToRem } from "../../../../../../../../../soe-theme/src/utils"
import CarrierDetailsCard from "../../../carrier-details-card"

function QuoteRow({ quotation, removable, onRemove }) {
  const { quote } = quotation

  const handleRemoveClick = () => {
    onRemove(quotation)
  }

  return (
    <TableRow sx={{
      backgroundColor: "white",
    }}>
      {
        removable && (
        <TableCell>
          <SoeCancelIcon onClick={handleRemoveClick} sx={{
            "&:hover": {
              cursor: "pointer",
            }
          }} />
        </TableCell>)
      }
      <TableCell align="center">
        <Typography>
          {quotation.orderId}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Currency amount={quote.rate.totals.discounted.total.amount} currency={quote.rate.totals.discounted.total.currency} currencyPosition="right" showSymbol />
      </TableCell>
      <TableCell>
        <CarrierDetailsCard
            service={quote.service}
            carrier={quote.carrier}
            commitment={quote.commitment}
            charges={quote.rate.charges}
            isThirdParty={quote.isThirdParty}
          />
      </TableCell>
    </TableRow>
  )
}

QuoteRow.propTypes = {
  quotation: PropTypes.instanceOf(Object).isRequired,
  removable: PropTypes.bool,
  onRemove: PropTypes.func,
}


QuoteRow.defaultProps = {
  removable: false,
  onRemove: () => {},
};

export default QuoteRow
