import React from 'react';
import { useTranslation } from "react-i18next"
import { Typography } from "@mui/material"
import { pxToRem } from "../../../../../../../soe-theme/src/utils"

function ModalTitle() {
  const { t } = useTranslation("orderManagement")

  return (
    <Typography component="h1" sx={{fontSize: pxToRem(35)}}>{t("modals.validation.title")}</Typography>
  )
}

export default ModalTitle;
