import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@mui/material"
import { useGetPendingShipmentsQuery } from "../../../slice"
import OrderGroup from "./components/order-group"

function getOrderIds(pendingShipments) {
  const orders = pendingShipments.flatMap((shipment)=>{
    return shipment.orders.flatMap((order)=>{
      return order.orders.flatMap((nestedOrder)=>{
        return nestedOrder.orders.map((deeplyNestedOrder)=>{
          return deeplyNestedOrder
          })
        })
      })
    })
  return orders
}

function PrintingQueueList() {
  const { data: pendingShipments } = useGetPendingShipmentsQuery()
  const { t } = useTranslation("printingQueue")
  const [orders, setOrders] = useState([])

  useEffect(() => {
    if (pendingShipments) {
      setOrders(getOrderIds(pendingShipments))
    }
  }, [pendingShipments]);


  return (
    <Box style={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems: 'center', overflow: 'auto' }}>
      {
        orders.length === 0
        ?
        <Typography>{t("noLabels")}</Typography>
        :
        orders.map((order) => <OrderGroup order={order} key={order.id} />)
      }
    </Box>
  )
}

export default PrintingQueueList;
