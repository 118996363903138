import React from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { Box, Button, Typography, CircularProgress } from "@mui/material"
import { pxToRem } from "../../../../../../../../soe-theme/src/utils"

function ButtonsRow({ onSave, onCancel, isLoading }) {
  const { t } = useTranslation("orderManagement")
  return (
    <Box sx={(theme)=> ({display: "flex", flexDirection: "row", justifyContent:  "space-evenly", padding: `${pxToRem(20)}`, backgroundColor: `${theme.palette.contentActionDefault.main}`})}>
      <Button onClick={onSave} disabled={isLoading} sx={(theme)=>({backgroundColor: `${theme.palette.contentActionLighter.main}`})}>
        {isLoading
          ?
            <CircularProgress size={pxToRem(20)} sx={() => ({ color: "white" })} />
          : (<Typography color="white">
            {t("buttons.save.label")}
          </Typography>)
        }
      </Button>
      <Button onClick={onCancel} disabled={isLoading} sx={(theme)=>({backgroundColor: `${theme.palette.contentActionLighter.main}`})}>
        <Typography>
          {t("buttons.cancel.label")}
        </Typography>
      </Button>
    </Box>
  )

}

ButtonsRow.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

ButtonsRow.defaultProps = {
  isLoading: false,
}


export default ButtonsRow