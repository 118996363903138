// excludes the "target" key from the error object of an API response so that it doens't automatically trigger
// the CustomAlert component by setting the "target" key of the "error" object, which is listened for in the "alertReducer"
// look at "baseError.result" block in "src/services/rest-api/slice.js"
// look at "extraReducerds" in "src/reducers/alertReducer.js"

const transformErrorResponse = (error) => {
  if (Object.prototype.hasOwnProperty.call(error, "target")) {
    const { target, ...rest } = error;
    return rest;
  }
  return error;
}

export default transformErrorResponse;
