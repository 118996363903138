// REACT
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

// MUI
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import CardContent from "@mui/material/CardContent"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import InputAdornment from "@mui/material/InputAdornment"
import Typography from "@mui/material/Typography"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"

// Theme
import { useTheme } from "@mui/material/styles"

// Formik
import { useFormik } from "formik"

// Custom
import CardContentNoPaddingBottom from "../../../../soe-theme/src/components/cardContent-no-padding-bottom"
import ConfirmSignUp from "../../../sign-up/components/confirm-sign-up"
import ModalAlert from "../../../modal/components/modal-alert"
import { SecondaryCustomButton, MainCustomButton, CustomTextField, CustomModalContainerBox } from "./style"
import { ROUTES } from "../../../../utils/constants"

import { signIn } from "../../../../reducers/authenticatorReducer"

import emailIcon from "../../../../assets/images/envelope.svg"
import passwordIcon from "../../../../assets/images/padlock.svg"
import passwordEyeIcon from "../../../../assets/images/password-eye.svg"

function LoginForm() {
  const customTheme = useTheme()
  const { t } = useTranslation("authenticator")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [passwordFieldType, setPasswordFieldType] = useState("password")
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)

  const handleEyeClick = (field, setField) => {
    if (field === "password") {
      setField("text")
    } else {
      setField("password")
    }
  }

  const handleCloseConfirmDialog = () => {
    setIsConfirmDialogOpen(false)
  }

  const handleOpenConfirmDialog = () => {
    setIsConfirmDialogOpen(true)
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: (formValues) => {
      const errors = {}
      if (!formValues.email) {
        errors.email = t("signIn.fields.email.error.required")
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {
        errors.email = t("signIn.fields.email.error.invalid")
      }
      if (!formValues.password) {
        errors.password = t("signIn.fields.password.error.required")
      }
      return errors
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (formValues) => {
      dispatch(signIn(formValues)).then((response) => {
        if (response.error?.code === "UserNotConfirmedException") handleOpenConfirmDialog()
        navigate(ROUTES.HOME) // TODO: go to Order Management (QMS) after log in once QMS is enabled for all users
      })
    },
  })

  return (
    <Box className="signUp" component="div" my="auto" sx={{ justifyContent: "center" }}>
      <Grid container direction="column" alignItems="center">
        <Box component="div" mb={2} mt={1} mx={2} sx={(theme) => ({ width: theme.utils.pxToRem(425) })}>
          <ModalAlert />
          <CardContentNoPaddingBottom sx={{ justifyContent: "center" }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <CustomTextField
                id="email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={emailIcon} alt="email-icon" />
                    </InputAdornment>
                  ),
                }}
                placeholder={t("inexSignIn.fields.email.label")}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && formik.errors.email !== undefined}
                helperText={(formik.touched.email && formik.errors.email) || " "}
                required
                autoFocus
              />
              <CustomTextField
                id="password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={passwordIcon} alt="password-icon" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" onClick={() => handleEyeClick(passwordFieldType, setPasswordFieldType)}>
                      <img src={passwordEyeIcon} alt="password-icon" />
                    </InputAdornment>
                  ),
                }}
                placeholder={t("inexSignIn.fields.password.label")}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={formik.touched.password && formik.errors.password !== undefined}
                helperText={(formik.touched.password && formik.errors.password) || " "}
                fullWidth
                required
                type={passwordFieldType}
              />
              <Box
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  alignItems: "center",
                  minWidth: "max-content"
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.termsConditionsChecked}
                      onChange={() => formik.setFieldValue("termsConditionsChecked", !formik.values.termsConditionsChecked)}
                      sx={{ color: customTheme.palette.inexTertiary.lighter, "&.Mui-checked": { color: customTheme.palette.inexPrimary.main } }}
                    />
                  }
                  label={<Box style={{ color: customTheme.palette.inexTertiary.main, fontFamily: "Poppins", fontSize: customTheme.utils.pxToRem(18) }}>{t("inexSignIn.rememberMe")}</Box>}
                />
                <Link onClick={() => navigate(ROUTES.RESET_PASSWORD, { replace: true })} style={{ fontFamily: "Poppins", fontSize: customTheme.utils.pxToRem(18), color: customTheme.palette.inexPrimary.main }}>
                  {t("inexSignIn.forgotPassword")}
                </Link>
              </Box>
              <CardContent sx={{ p: customTheme.utils.pxToRem(20) }} />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <MainCustomButton type="submit" variant="outlined" size="medium">
                  <Typography variant="bodyMedium600" component="span" style={{ fontFamily: "Poppins", fontWeight: "600" }}>
                    {t("inexSignIn.fields.signInButton.label")}
                  </Typography>
                </MainCustomButton>
                <Divider orientation="vertical" style={{ height: "0px", margin: `0 ${customTheme.utils.pxToRem(10)} 0 ${customTheme.utils.pxToRem(10)}` }} />
                <SecondaryCustomButton onClick={() => navigate(ROUTES.SIGN_UP, { replace: true })} variant="outlined" size="medium">
                  <Typography variant="bodyMedium600" component="span" style={{ fontFamily: "Poppins", fontWeight: "600" }}>
                    {t("inexSignIn.fields.signUpButton.label")}
                  </Typography>
                </SecondaryCustomButton>
              </Box>
              <CardContent sx={(theme) => ({ p: theme.utils.pxToRem(16) })} />
            </form>
          </CardContentNoPaddingBottom>
        </Box>
      </Grid>

      <Modal open={isConfirmDialogOpen} onClose={handleCloseConfirmDialog}>
        <CustomModalContainerBox className="authContainer" component="div">
          <ConfirmSignUp onSubmit={handleCloseConfirmDialog}/>
        </CustomModalContainerBox>
      </Modal>
    </Box>
  )
}

export default LoginForm
