import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import RateRequestSummaryCard from "../rate-request-summary-card"

import { isShipperComplete } from "../quotations/utils"
import { ShipperProps } from "../../models/rate-request"

function ShipperCard(
  {
     shipper,
     setShowRateRequestDrawer,
     isEditable,
     showPhoneNumber,
     showEmailAddress,
    isValid: customIsShipperComplete,
     ...sw })
{
  const { t } = useTranslation("quotations")
  const theme = useTheme()

  const showRateRequestDrawer = () => {
    sw.stepWizard.goToNamedStep("shipper")
    setShowRateRequestDrawer(true)
  }

  // use optional prop if provided. to accommodate better validation used in the Order Management Table
  const summaryStatusProps = {
    variant: customIsShipperComplete !== undefined
      ? (customIsShipperComplete ? "success" : "warning")
      : (isShipperComplete(shipper) ? "success" : "warning")
  }
  return (
    <RateRequestSummaryCard title={t("shipperTitle")} handleClick={showRateRequestDrawer} summaryStatusProps={summaryStatusProps} showStatus={isEditable} isClickable={isEditable}>
      {shipper && (
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {shipper.companyName && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {shipper.companyName}
            </Typography>
          )}
          {shipper.personName && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {shipper.personName}
            </Typography>
          )}
          {shipper.addressLine1 && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {shipper.addressLine1}
            </Typography>
          )}
          {shipper.addressLine2 && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {shipper.addressLine2}
            </Typography>
          )}
          {(shipper.city || shipper.provinceCode || shipper.countryCode) && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {`${shipper.city || ""}${shipper.city && (shipper.provinceCode || shipper.provinceName) ? ", " : ""}${shipper.provinceCode || shipper.provinceName || ""}${
                (shipper.city || shipper.provinceCode || shipper.provinceName) && (shipper.countryCode || shipper.countryName) ? ", " : ""
              }${shipper.countryCode || shipper.countryName || ""}`}
            </Typography>
          )}
          {shipper.postalCode && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {shipper.postalCode}
            </Typography>
          )}
          {(showPhoneNumber || showEmailAddress) && (
            <Box
              mt={1}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {showPhoneNumber && shipper.phoneNumber && (
                <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                  {shipper.phoneNumber}
                </Typography>
              )}
              {showEmailAddress && shipper.emailAddress && (
                <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                  {shipper.emailAddress}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      )}
    </RateRequestSummaryCard>
  )
}

ShipperCard.propTypes = {
  shipper: ShipperProps,
  setShowRateRequestDrawer: PropTypes.func,
  isEditable: PropTypes.bool,
  showPhoneNumber: PropTypes.bool,
  showEmailAddress: PropTypes.bool,
  isValid: PropTypes.bool,
}

ShipperCard.defaultProps = {
  shipper: undefined,
  setShowRateRequestDrawer: () => {},
  isEditable: true,
  showPhoneNumber: false,
  showEmailAddress: false,
  isValid: undefined,
}

export default ShipperCard
