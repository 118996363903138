import React from "react"
import PropTypes from "prop-types"
import { Box, Button, Typography, CircularProgress } from "@mui/material"
import { pxToRem } from "../../../../../../../soe-theme/src/utils"

function ModalButtonsRow({ onLeftButtonClick, onRightButtonClick, isLoading, leftButtonLabel, rightButtonLabel }) {
  return (
    <Box sx={(theme)=> ({width:"100%", position: "sticky", bottom: 0, zIndex: 1 , display: "flex", flexDirection: "row", justifyContent:  "space-evenly", padding: `${pxToRem(20)}`, backgroundColor: `${theme.palette.contentActionDefault.main}`})}>
      <Button onClick={onLeftButtonClick} disabled={isLoading} sx={(theme)=>({backgroundColor: `${theme.palette.contentActionLighter.main}`})}>
        {isLoading
          ?
          <CircularProgress size={pxToRem(20)} sx={() => ({ color: "white" })} />
          : (<Typography>
            {leftButtonLabel}
          </Typography>)
        }
      </Button>
      <Button onClick={onRightButtonClick} sx={(theme)=>({backgroundColor: `${theme.palette.contentActionLighter.main}`})}>
        <Typography>
          {rightButtonLabel}
        </Typography>
      </Button>
    </Box>
  )

}

ModalButtonsRow.propTypes = {
  onLeftButtonClick: PropTypes.func.isRequired,
  leftButtonLabel: PropTypes.string.isRequired,
  onRightButtonClick: PropTypes.func.isRequired,
  rightButtonLabel: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
}

ModalButtonsRow.defaultProps = {
  isLoading: false,
}


export default ModalButtonsRow