import React from "react"
import { useTranslation } from "react-i18next"
import { Alert, Typography } from "@mui/material"
import { useQuotationContext } from "../../state/quotation-provider"

function AlertContainer() {
  const { alert, clearAlert } = useQuotationContext();
  const {t} = useTranslation("orderManagement");

  if (!alert) return null

  return (
    <Alert severity={alert.severity} onClose={() => clearAlert()}>
      <Typography>
        {alert.message || t("alert.error.default.label")}
      </Typography>
    </Alert>
  )
}

export default AlertContainer
