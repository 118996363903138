import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useGetAddressFormatsQuery, useGetCountriesQuery } from "../../../../services/address-form/slice"
import { getAddressFormat, isRequiredField } from "../../../../services/address-form"
import { isValidEmail, isValidPhoneNumber } from "../../../quotations/components/rate-request/utils"
import PACKAGING_TYPE from "../../../quotations/components/rate-request/components/rate-request-packages/constants"
import DateTimeService from "../../../../services/date-time"
import { isFreightOptionsRequired, isInternationalShipment, isWeightsMatching } from "../../../quotations/utils"
import { BILL_TO_TYPES, FREIGHT_OPTIONS } from "../../../../utils/constants"
import { billDutyToListCC, businessRelationshipList, importExportList } from "../../../../utils/international-options"

const useOrderValidation = (order) => {
  const { t } = useTranslation("orderManagement")
  const currentUser = useSelector((state) => state.user)
  const { data: countries, isLoading: countriesLoading } = useGetCountriesQuery()
  const { data: addressFormats, isLoading: addressFormatsLoading } = useGetAddressFormatsQuery()
  const errors = {}

  const isLoading = countriesLoading || addressFormatsLoading

  const isOrderIdComplete = () => {
    if (!order.orderId) {
      errors.orderId = t("errors.orderId.label")
      return false
    }
    return true
  }

  const isShipperComplete = (shipper) => {
    if (!shipper) {
      errors.shipper = t("errors.shipper.label")
      return false
    }

    const countryCode =
      shipper.countryCode ||
      currentUser?.company?.clientAddress?.countryCode ||
      currentUser?.clientAddress?.countryCode ||
      "CA"

    const addressFormat = getAddressFormat({ addressFormats, countries, countryCode })

    let addressError = false
    addressFormat?.forEach((fieldFormat) => {
      if (
        isRequiredField({ addressFormat, fieldName: fieldFormat.name }) &&
        !shipper[fieldFormat.name]
      ) {
        addressError = true
      }
    })

    if (addressError) {
      errors.shipper = t("errors.shipper.label")
      return false
    }

    if (!shipper.personName?.trim()) {
      errors.shipper = t("errors.shipper.label")
      return false
    }
    if (!shipper.phoneNumber?.trim()) {
      errors.shipper = t("errors.shipper.label")
      return false
    }

    const isPhoneNumberValid = isValidPhoneNumber(shipper.phoneNumber)
    if (shipper.phoneNumber && !isPhoneNumberValid) {
      errors.shipper = t("errors.shipper.label")
      return false
    }

    const isEmailValid = isValidEmail(shipper.emailAddress)
    if (shipper.emailAddress && !isEmailValid) {
      errors.shipper = t("errors.shipper.label")
      return false
    }

    return true
  }

  const isRecipientComplete = (recipient) => {
    if (!recipient) {
      errors.recipient = t("errors.recipient.label")
      return false
    }

    const countryCode =
      recipient.countryCode ||
      currentUser?.company?.clientAddress?.countryCode ||
      currentUser?.clientAddress?.countryCode ||
      "CA"

    const addressFormat = getAddressFormat({ addressFormats, countries, countryCode })

    let addressError = false
    addressFormat?.forEach((fieldFormat) => {
      if (
        isRequiredField({ addressFormat, fieldName: fieldFormat.name }) &&
        !recipient[fieldFormat.name]
      ) {
        addressError = true
      }
    })

    if (addressError) {
      errors.recipient = t("errors.recipient.label")
      return false
    }

    if (!recipient.personName?.trim()) {
      errors.recipient = t("errors.recipient.label")
      return false
    }
    if (!recipient.phoneNumber?.trim()) {
      errors.recipient = t("errors.recipient.label")
      return false
    }

    const isPhoneNumberValid = isValidPhoneNumber(recipient.phoneNumber)
    if (recipient.phoneNumber && !isPhoneNumberValid) {
      errors.recipient = t("errors.recipient.label")
      return false
    }

    const isEmailValid = isValidEmail(recipient.emailAddress)
    if (recipient.emailAddress && !isEmailValid) {
      errors.recipient = t("errors.recipient.label")
      return false
    }

    return true
  }

  const isPackagesComplete = (packages) => {
    if (!packages || packages.pieces.length === 0) {
      errors.packages = t("errors.packages.label")
      return false
    }

    const packagesErrors = {
      pieces: [],
    }
    packages.pieces.forEach((piece) => {
      const error = {}
      if (!piece.packagingType) {
        error.packagingType = true
      }
      if (!piece.quantity || piece.quantity < 0) {
        error.quantity = true
      }
      if (!piece.length || piece.length < 0) {
        error.length = true
      }
      if (!piece.width || piece.width < 0) {
        error.width = true
      }
      if (!piece.height || piece.height < 0) {
        error.height = true
      }
      if (!piece.packagingType?.isEnvelope && (!piece.weight || piece.weight < 0)) {
        error.weight = true
      }
      if (piece.packagingType?.categoryName === PACKAGING_TYPE.FREIGHT && (!piece.itemCount || piece.itemCount < 0)) {
        error.itemCount = true
      }
      packagesErrors.pieces.push(error)
    })
    let isValid = true

    packagesErrors.pieces.forEach((err) => {
      if (Object.keys(err).length > 0) {
        isValid = false
      }
    })
    if (!isValid) {
      errors.packages = t("errors.packages.label")
      return false
    }
    return true
  }

  const isShippingOptionsComplete = ({ shipper, recipient, shippingOptions }) => {

    if (!shipper || !recipient || !shippingOptions) {
      errors.shippingOptions = t("errors.shippingOptions.label")
      return false
    }
    if (!shippingOptions.shippingDate) {
      errors.shippingOptions = t("errors.shippingOptions.label")
      return false
    }
    // TODO: new system for shippingOptions.shippingData. Right now we overwrite this value in the backend when getting a quote
    // and in the frontend we're not validating the shipping date + setting the form value to today.
    // const utcShippingDateEndOfDay = DateTimeService.getZonedEndOfDayToUtc(shippingOptions.shippingDate, shippingOptions.shippingDateTimezone)
    // if (new Date() > utcShippingDateEndOfDay) {
    //   errors.shippingOptions = t("errors.shippingOptions.label")
    //   return false
    // }

    if (!isInternationalShipment(shipper, recipient) && shippingOptions.insurance && !shippingOptions.insurance.amount) {
      errors.shippingOptions = t("errors.shippingOptions.label")
      return false
    }
    return true
  }

  const isBillingOptionsComplete = ({ billingOptions }) => {
    if (!billingOptions || billingOptions.type === BILL_TO_TYPES.myAccount) {
      return true
    }

    const requiredFields = [
      "type",
      "carrierCode",
      "thirdPartyShipperNumber",
      "postalCode",
      "countryCode",
      "name"
    ];
    const allFieldsPresent = requiredFields.every(field => billingOptions[field]);
    if (!allFieldsPresent) {
      errors.billingOptions = t("errors.billingOptions.label")
      return false
    }

    if (billingOptions.carrierCode === "fedex-freight") {
      const requiredFreightFields = [
        "companyName",
        "addressLine1",
        "city",
        "countryCode",
        "postalCode",
        "phoneNumber"
      ];
      const allFreightFieldsPresent = requiredFreightFields.every(field => billingOptions[field]);
      if (!allFreightFieldsPresent) {
        errors.billingOptions = t("errors.billingOptions.label")
        return false
      }
    }

    return true
  }

  const isFreightOptionsComplete = ({ packages, freightOptions }) => {

    if (!isFreightOptionsRequired(packages)) return true

    if (!freightOptions) {
      errors.freightOptions = t("errors.freightOptions.label")
      return false
    }

    const appointmentDateRegex = /^([0-9]{4}-[0-9]{2}-[0-9]{2})$/
    const appointmentTimeRegex = /^(([0-1]?[0-9]|2[0-3]):[0-5][0-9])$/

    if (Object.prototype.hasOwnProperty.call(freightOptions, "appointment") &&
      freightOptions.appointment === "")
    {
      errors.freightOptions = t("errors.freightOptions.label")
      return false
    }
    if (Object.prototype.hasOwnProperty.call(freightOptions, "prebookedAppointment") && freightOptions.prebookedAppointment &&
      (!freightOptions.prebookedAppointment?.appointmentDate || !new RegExp(appointmentDateRegex, "g").test(freightOptions.prebookedAppointment?.appointmentDate))
      ) {
      errors.freightOptions = t("errors.freightOptions.label")
      return false
    }
    if ((Object.prototype.hasOwnProperty.call(freightOptions, "prebookedAppointment") && freightOptions.prebookedAppointment &&
      (!freightOptions.prebookedAppointment?.appointmentTime || !new RegExp(appointmentTimeRegex, "g").test(freightOptions.prebookedAppointment?.appointmentTime))
      )) {
      errors.freightOptions = t("errors.freightOptions.label")
      return false
    }
    if (
      (Object.prototype.hasOwnProperty.call(freightOptions, "afterHours")  &&
        freightOptions.afterHours !== undefined &&
        !FREIGHT_OPTIONS.AFTER_HOURS.some(option => option.value === freightOptions.afterHours)
      )) {
      errors.freightOptions = t("errors.freightOptions.label")
      return false
    }
    if (
      (Object.prototype.hasOwnProperty.call(freightOptions, "twoPersons")  &&
        freightOptions.twoPersons !== undefined &&
        !FREIGHT_OPTIONS.TWO_PERSONS.some(option => option.value === freightOptions.twoPersons)
      )) {
      errors.freightOptions = t("errors.freightOptions.label")
      return false
    }
    if (
      (Object.prototype.hasOwnProperty.call(freightOptions, "tailgate")  &&
        freightOptions.tailgate !== undefined &&
        !FREIGHT_OPTIONS.TAILGATE.some(option => option.value === freightOptions.tailgate)
      )) {
      errors.freightOptions = t("errors.freightOptions.label")
      return false
    }
    return true
  }

  const isInternationalOptionsComplete = ({ shipper, recipient, internationalOptions }) => {
    if (!isInternationalShipment(shipper, recipient)) return true

    if (!internationalOptions) {
      errors.internationalOptions = t("errors.internationalOptions.label")
      return false
    }

    if (internationalOptions.documentsOnly === true) return true

    if (
      !internationalOptions.billDutyTo ||
      !billDutyToListCC.some(option => option.value === internationalOptions.billDutyTo)
    ) {
      errors.internationalOptions = t("errors.internationalOptions.label")
      return false
    }

    if (
      internationalOptions.dutyAccountNumber && (/\s/).test(internationalOptions.dutyAccountNumber)
    ) {
      errors.internationalOptions = t("errors.internationalOptions.label")
      return false
    }

    if (
      internationalOptions.businessRelationship &&
      !businessRelationshipList.some(option => option.value === internationalOptions.businessRelationship)
    ) {
      errors.internationalOptions = t("errors.internationalOptions.label")
      return false
    }

    if (
      internationalOptions.exportType &&
      !importExportList.some(option => option.value === internationalOptions.exportType)
    ) {
      errors.internationalOptions = t("errors.internationalOptions.label")
      return false
    }

    return true
  }

  const isCommercialInvoiceComplete = ({ shipper, recipient, commercialInvoice, packages, internationalOptions }) => {
    if (!isInternationalShipment(shipper, recipient)) return true

    if (internationalOptions?.documentsOnly) return true

    if (!commercialInvoice || !commercialInvoice.invoiceItems) {
      errors.commercialInvoiceItems = t("errors.commercialInvoice.label")
      return false
    }

    const commercialInvoiceErrors = {
      invoiceItems: [],
    }

    if (!isWeightsMatching(packages, commercialInvoice)) commercialInvoiceErrors.isWeightMatching = true

    commercialInvoice?.invoiceItems.forEach((invoice) => {
      const error = {}

      if (!invoice.description) {
        error.description = true
      }
      if (!invoice.quantity || invoice.quantity < 0) {
        error.quantity = true
      }
      if (!invoice.value || invoice.value < 0) {
        error.value = true
      }

      if (!invoice.weight || invoice.weight < 0) {
        error.weight = true
      }

      if (!invoice.hsCode) {
        error.hsCode = true
      }
      commercialInvoiceErrors.invoiceItems.push(error)
    })

    // TODO: double check if we need to validate commercialInvoice.buyerRequired
    // if (buyer && Object.keys(buyer).length === 0) {
    //   errors.selectedBuyer = true
    // }

    let isValid = true

    commercialInvoiceErrors.invoiceItems.forEach((err) => {
      if (Object.keys(err).length > 0) {
        isValid = false
      }
    })
    if (!isValid) {
      errors.commercialInvoice = t("errors.commercialInvoice.label")
      return false
    }
    return true
  }

  const isBuyerComplete = ({ buyer, commercialInvoice }) => {
    if (!commercialInvoice?.buyerRequired) return true

    if (!buyer) {
      errors.buyer = t("errors.buyer.label")
      return false
    }

    const countryCode =
      buyer.countryCode ||
      currentUser?.company?.clientAddress?.countryCode ||
      currentUser?.clientAddress?.countryCode ||
      "CA"

    const addressFormat = getAddressFormat({ addressFormats, countries, countryCode })

    let addressError = false
    addressFormat?.forEach((fieldFormat) => {
      if (
        isRequiredField({ addressFormat, fieldName: fieldFormat.name }) &&
        !buyer[fieldFormat.name]
      ) {
        addressError = true
      }
    })

    if (addressError) {
      errors.buyer = t("errors.buyer.label")
      return false
    }

    if (!buyer.personName?.trim()) {
      errors.buyer = t("errors.buyer.label")
      return false
    }
    if (!buyer.phoneNumber?.trim()) {
      errors.buyer = t("errors.buyer.label")
      return false
    }

    const isPhoneNumberValid = isValidPhoneNumber(buyer.phoneNumber)
    if (buyer.phoneNumber && !isPhoneNumberValid) {
      errors.buyer = t("errors.buyer.label")
      return false
    }

    const isEmailValid = isValidEmail(buyer.emailAddress)
    if (buyer.emailAddress && !isEmailValid) {
      errors.buyer = t("errors.buyer.label")
      return false
    }

    return true
  }

  const isBrokerComplete = ({ broker, internationalOptions }) => {

    if (!internationalOptions?.brokerRequired) return true

    if (!broker) {
      errors.broker = t("errors.broker.label")
      return false
    }

    const countryCode =
      broker.countryCode ||
      currentUser?.company?.clientAddress?.countryCode ||
      currentUser?.clientAddress?.countryCode ||
      "CA"

    const addressFormat = getAddressFormat({ addressFormats, countries, countryCode })

    let addressError = false
    addressFormat?.forEach((fieldFormat) => {
      if (
        isRequiredField({ addressFormat, fieldName: fieldFormat.name }) &&
        !broker[fieldFormat.name]
      ) {
        addressError = true
      }
    })

    if (addressError) {
      errors.broker = t("errors.broker.label")
      return false
    }

    if (!broker.personName?.trim()) {
      errors.broker = t("errors.broker.label")
      return false
    }
    if (!broker.phoneNumber?.trim()) {
      errors.broker = t("errors.broker.label")
      return false
    }

    const isPhoneNumberValid = isValidPhoneNumber(broker.phoneNumber)
    if (broker.phoneNumber && !isPhoneNumberValid) {
      errors.broker = t("errors.broker.label")
      return false
    }

    const isEmailValid = isValidEmail(broker.emailAddress)
    if (broker.emailAddress && !isEmailValid) {
      errors.broker = t("errors.broker.label")
      return false
    }

    return true
  }

  const orderIdComplete = isOrderIdComplete()
  const shipperComplete = isShipperComplete(order.shipper)
  const recipientComplete = isRecipientComplete(order.recipient)
  const packagesComplete = isPackagesComplete(order.packages)
  const shippingOptionsComplete = isShippingOptionsComplete({ shipper: order.shipper, recipient: order.recipient, shippingOptions: order.shippingOptions})
  const billingOptionsComplete = isBillingOptionsComplete({ billingOptions: order.billingOptions })
  const freightOptionsComplete = isFreightOptionsComplete({ packages: order.packages, freightOptions: order.freightOptions })
  const internationalOptionsComplete = isInternationalOptionsComplete({ shipper: order.shipper, recipient: order.recipient, internationalOptions: order.internationalOptions })
  const commercialInvoiceComplete = isCommercialInvoiceComplete({ commercialInvoice: order.commercialInvoice, packages: order.packages, shipper: order.shipper, recipient: order.recipient, internationalOptions: order.internationalOptions })
  const buyerComplete = isBuyerComplete({ buyer: order.buyer, commercialInvoice: order.commercialInvoice })
  const brokerComplete = isBrokerComplete({ broker: order.broker, internationalOptions: order.internationalOptions })

  return {
    orderIdComplete,
    shipperComplete,
    recipientComplete,
    packagesComplete,
    shippingOptionsComplete,
    billingOptionsComplete,
    freightOptionsComplete,
    internationalOptionsComplete,
    commercialInvoiceComplete,
    buyerComplete,
    brokerComplete,
    isOrderComplete: orderIdComplete && shipperComplete && recipientComplete && packagesComplete && shippingOptionsComplete && billingOptionsComplete && freightOptionsComplete && internationalOptionsComplete && commercialInvoiceComplete && buyerComplete && brokerComplete,
    isLoading,
    errors,
  }
}

export default useOrderValidation
