import React from "react"
import PropTypes from "prop-types"
import AddressSummary from "../../../../../../address-summary"

function RecipientInfo({ recipient, recipientComplete }) {
  if (!recipient || !recipientComplete) return null
  return (
    <AddressSummary addressSummary={recipient} />
  )
}

RecipientInfo.propTypes = {
  recipient: PropTypes.instanceOf(Object),
  recipientComplete: PropTypes.bool.isRequired,
}

RecipientInfo.defaultProps = {
  recipient: undefined,
}

export default RecipientInfo
