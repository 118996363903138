import React from "react"
import { SoeDrawerWizard } from "../../../../../../../soe-theme/src/components"
import RateRequestShipper from "./components/rate-request-shipper"
import { useQuotationContext } from "../../../../../state/quotation-provider"
import RateRequestRecipient from "./components/rate-request-recipient"
import RateRequestPackages from "./components/rate-request-packages"
import RateRequestShippingOptions from "./components/rate-request-shipping-options"
import RateRequestBillingOptions from "./components/rate-request-billing-options"
import RateRequestFreightOptions from "./components/rate-request-freight-options"
import RateRequestInternationalOptions from "./components/rate-request-international-options"
import RateRequestBroker from "./components/rate-request-broker"
import RateRequestCommercialInvoice from "./components/rate-request-commercial-invoice"
import RateRequestBuyer from "./components/rate-request-buyer"
import { pxToRem } from "../../../../../../../soe-theme/src/utils"

function RateRequestForm() {
  const { setRateRequestStepWizard, showRateRequestForm } = useQuotationContext()

  return (
    <SoeDrawerWizard
      hidden={!showRateRequestForm}
      isLazyMount // this allows the sizing of each form screen to reset (prevents scroll issues)
      setStepWizardInstance={(wizardInstance) => setRateRequestStepWizard(wizardInstance)}
      sx={{ height: "100%", overflow: "hidden", px: `${pxToRem(40)}` }}
    >
      <RateRequestShipper stepName="shipper" />
      <RateRequestRecipient stepName="recipient" />
      <RateRequestPackages stepName="packages" />
      <RateRequestShippingOptions stepName="shipping-options" />
      <RateRequestBillingOptions stepName="billing-options" />
      <RateRequestFreightOptions stepName="freight-options" />
      <RateRequestInternationalOptions stepName="international-options" />
      <RateRequestBroker stepName="broker" />
      <RateRequestCommercialInvoice stepName="commercial-invoice" />
      <RateRequestBuyer stepName="buyer" />
    </SoeDrawerWizard>
  )
}

export default RateRequestForm
