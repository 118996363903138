import React from "react"
import { useTranslation } from "react-i18next"
import { Alert, Typography } from "@mui/material"
import { useValidationContext } from "../../state/provider"

function AlertContainer() {
  const {modalAlert, clearModalAlert} = useValidationContext()
  const { t } = useTranslation("orderManagement")

  if (!modalAlert) return null

  return (
    <Alert severity={modalAlert.severity} onClose={() => clearModalAlert()}>
      <Typography>
        {modalAlert.message || t("alert.error.default.label")}
      </Typography>
    </Alert>
  )
}

export default AlertContainer
