import React from "react"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material/styles"
import PropTypes from "prop-types"
import { Paper, TableContainer, Table, TableBody } from "@mui/material"
import QuoteRow from "./components/quote-row"
import EnhancedTableHead from "../../../../../../enhanced-table-head"
import { pxToRem } from "../../../../../../../soe-theme/src/utils"

function InvalidQuotesList({ quotes }) {
  const theme = useTheme()
  const { t } = useTranslation("orderManagement")

  const headCells = [
    {
      id: "orderId",
      disablePadding: false,
      label: t("modals.validation.table.headers.orderId.label"),
    },
    {
      id: "quotePrice",
      disablePadding: false,
      label: t("modals.validation.table.headers.price.label"),
    },
    {
      id: "carrier",
      disablePadding: false,
      label: t("modals.validation.table.headers.carrier.label"),
    },
    {
      id: "error",
      disablePadding: false,
      label: t("modals.validation.table.headers.error.label"),
    },
  ]

  return (
    <Paper sx={{ width: "100%", padding: pxToRem(20) }}>
      <TableContainer>
        <Table size="small" sx={{ backgroundColor: theme.palette.backgroundNeutralUnderground1.main }}>
          <EnhancedTableHead headCells={headCells} order="desc" orderBy="orderId" onRequestSort={()=>{}} />
          <TableBody>
            {quotes.map((quotation) => {
              if (!quotation.quote) return null
              return <QuoteRow key={quotation.rateRequestId} quotation={quotation} />
            }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )

}

InvalidQuotesList.propTypes = {
  quotes: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

export default InvalidQuotesList;
