import React from 'react';
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import {
  SoeDrawer,
  SoeDrawerActions,
  SoeDrawerContainer,
  SoeDrawerContent,
  SoeDrawerHeader
} from "../../../../../soe-theme/src/components"
import CarrierDetailsCard from "../../../../carrier-details-card"
import PriceSummaryCard from "../../../../price-summary-card"

function QuotePriceDetailsDrawer({ showPaymentDetailsDrawer, setShowPaymentDetailsDrawer, quote }) {
  const { t } = useTranslation("orderManagement")

  const handleCloseClick = () => {
    setShowPaymentDetailsDrawer(false)
  }
  return (
    <SoeDrawer showDrawer={showPaymentDetailsDrawer} setShowDrawer={setShowPaymentDetailsDrawer} anchor="right" keepMounted={false}>
      <SoeDrawerContainer>
        <SoeDrawerHeader title={t("drawer.quotePriceDetails.title.label")} setShowDrawer={setShowPaymentDetailsDrawer} />
        <SoeDrawerContent>
          <CarrierDetailsCard
            service={quote.service}
            carrier={quote.carrier}
            commitment={quote.commitment}
            charges={quote.rate.charges}
            isBestPrice={quote.isBestPrice}
            isBestChoice={quote.isBestChoice}
            isFastDelivery={quote.isFastDelivery}
            isThirdParty={quote.isThirdParty}
          />
          <PriceSummaryCard rate={quote.rate} />
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={[
            {
              action: handleCloseClick,
              label: t("drawer.quotePriceDetails.buttons.close.label"),
              variant: "outlined",
            },
          ]}
        />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

QuotePriceDetailsDrawer.propTypes = {
  showPaymentDetailsDrawer: PropTypes.bool.isRequired,
  setShowPaymentDetailsDrawer: PropTypes.func.isRequired,
  quote: PropTypes.instanceOf(Object).isRequired,
}

export default QuotePriceDetailsDrawer
