import { format, parse, parseISO } from "date-fns"
import { getAddressSearchText } from "../../../utils/search"

// TODO: use the "getAddressSearchText" function from */utils/search.js to convert the address object into a single string

function handleCreatedDateSearch(quotation, value) {
  const quotationDate = new Date(quotation.createdDate);
  const enUSFormat = quotationDate.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  }).toLowerCase();
  const frFRFormat = quotationDate.toLocaleDateString('fr-FR', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  }).toLowerCase();

  return enUSFormat.includes(String(value).toLowerCase()) ||
    frFRFormat.includes(String(value).toLowerCase());
}

function searchQuotations (quotations, key, value) {
  return quotations.filter(quotation => {
    switch (key) {
      case 'orderId':
        return String(quotation.orderId)
          .toLowerCase()
          .includes(String(value).toLowerCase());

      case 'shipper':
        return quotation.shipper &&
          String(getAddressSearchText(quotation.shipper))
            .toLowerCase()
            .includes(String(value).toLowerCase());

      case 'recipient':
        return quotation.recipient &&
          String(getAddressSearchText(quotation.recipient))
            .toLowerCase()
            .includes(String(value).toLowerCase());

      case 'createdDate':
        return handleCreatedDateSearch(quotation, value);

      default:
        return quotation[key] === value;
    }
  });
};



export default searchQuotations;