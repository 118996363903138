import React from "react"
import PropTypes from "prop-types"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { Tooltip } from "@mui/material"

function DropdownSelect({options, onChange, value, tooltipText, ...props}) {

  return (
    <Tooltip title={tooltipText} placement="left-end">
      <Select variant="outlined" name="shippingType" onClick={(event)=>event.stopPropagation()} onChange={onChange} value={value} size="small" defaultValue="">
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Tooltip>
  )
}

DropdownSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  ...Select.propTypes,
}

DropdownSelect.defaultProps = {
  ...Select.defaultProps,
  tooltipText: ``,
};

export default DropdownSelect
