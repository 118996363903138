import React from "react"
import PropTypes from "prop-types"
import { Button } from "@mui/material"
import SoeArrowBackIcon from "../../../../../../../../soe-theme/src/icons/SoeArrowBackIcon"
import { pxToRem } from "../../../../../../../../soe-theme/src/utils"

function CloseFormButton({ onClick }) {
  return (
    <Button onClick={onClick} sx={{ minWidth: "auto", padding: pxToRem(5), borderRadius: pxToRem(100) }}>
      <SoeArrowBackIcon />
    </Button>
  )
}

CloseFormButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default CloseFormButton
