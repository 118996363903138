import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import { Typography } from "@mui/material"
import { useGetAddressFormatsQuery, useGetCountriesQuery } from "../../../../../../../../../services/address-form/slice"
import { useAddAddressBookItemMutation, useEditAddressBookItemMutation } from "../../../../../../../../address-book/slice"
import { getAddressFormat, isRequiredField } from "../../../../../../../../../services/address-form"
import { AddressTypeEnum } from "../../../../../../../../../constants/quotationsConstants"
import FullAddressForm from "../../../../../../../../full-address-form"
import {
  SoeDrawerActions,
  SoeDrawerContainer,
  SoeDrawerContent
} from "../../../../../../../../../soe-theme/src/components"
import { useQuotationContext } from "../../../../../../../state/quotation-provider"


function RateRequestBroker() {
  const { rateRequestStepWizard, selectedQuotation, setBroker, setShowRateRequestForm } = useQuotationContext()

  const { t } = useTranslation("fullAddressForm")
  const { t: tOrderManagement } = useTranslation("orderManagement")

  const currentUser = useSelector((state) => state.user)

  const [action, setAction] = useState("")
  const [saveDefaultAddressChecked, setSaveDefaultAddressChecked] = useState(false)
  const [saveDefaultAddressDisabled, setSaveDefaultAddressDisabled] = useState(false)

  const { data: countries } = useGetCountriesQuery()
  const { data: addressFormats } = useGetAddressFormatsQuery()

  const [addAddressBookItem, { data: newAddressBookItem }] = useAddAddressBookItemMutation()
  const [editAddressBookItem, { data: updatedAddressBookItem }] = useEditAddressBookItemMutation()

  useEffect(() => {
    if (newAddressBookItem && newAddressBookItem.data) {
      setBroker(newAddressBookItem.data)
    }

    if (updatedAddressBookItem && updatedAddressBookItem.data) {
      setBroker(updatedAddressBookItem.data)
    }
  }, [newAddressBookItem, updatedAddressBookItem])

  const formik = useFormik({
    initialValues: {
      id: selectedQuotation.broker?.id || undefined,
      companyName: selectedQuotation.broker?.companyName || "",
      personName: selectedQuotation.broker?.personName || "",
      addressLine1: selectedQuotation.broker?.addressLine1 || "",
      addressLine2: selectedQuotation.broker?.addressLine2 || "",
      city: selectedQuotation.broker?.city || "",
      provinceName: selectedQuotation.broker?.provinceName || "",
      provinceCode: selectedQuotation.broker?.provinceCode || "",
      countryCode: selectedQuotation.broker?.countryCode || currentUser?.company?.clientAddress?.countryCode || currentUser.clientAddress.countryCode || "CA",
      postalCode: selectedQuotation.broker?.postalCode || "",
      residential: selectedQuotation.broker?.residential || undefined,
      phoneNumber: selectedQuotation.broker?.phoneNumber || "",
      emailAddress: selectedQuotation.broker?.emailAddress || "",
      timezone: selectedQuotation.broker?.timezone || undefined,
      validationProviderId: selectedQuotation.broker?.validationProviderId || undefined,
    },
    validate: async (formValues) => {
      const errors = {}

      if (!formValues.companyName.trim()) {
        errors.companyName = t("fields.companyName.error.required")
      }

      if (!formValues.personName.trim()) {
        errors.personName = t("fields.personName.error.required")
      }

      const addressFormat = getAddressFormat({ addressFormats, countries, countryCode: formValues.countryCode })

      addressFormat?.forEach((fieldFormat) => {
        if (isRequiredField({ addressFormat, fieldName: fieldFormat.name }) && !formValues[fieldFormat.name]) {
          errors[fieldFormat.name] = t(`fields.${fieldFormat.name}.error.required`)
        }
      })

      if (!formValues.phoneNumber.trim()) {
        errors.phoneNumber = t("fields.phoneNumber.error.required")
      }

      if (!formValues.emailAddress.trim()) {
        errors.emailAddress = t("fields.emailAddress.error.required")
      }

      return errors
    },
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (formValues) => {
      formValues = { ...formValues, type: AddressTypeEnum.Broker }
      if (saveDefaultAddressChecked) {
        formValues = { ...formValues, isDefaultBroker: true }
      }
      if (formValues.id) {
        editAddressBookItem({ id: formValues.id, payload: formValues })
      } else {
        addAddressBookItem({ ...formValues })
      }

      if (action === "continue") {
        rateRequestStepWizard.nextStep()
      }
      if (action === "saveAndClose") setShowRateRequestForm(false)
    },
  })

  useEffect(() => {
    if (!setShowRateRequestForm) formik.resetForm()
  }, [setShowRateRequestForm])

  useEffect(() => {
    formik.setErrors({})
    formik.setTouched({})
  }, [formik.values.countryCode])

  const handleSaveAndCloseClick = () => {
    formik.handleSubmit()
    setAction("saveAndClose")
  }

  useEffect(() => {
    if (formik.values.id && formik.values.id === currentUser.defaultBrokerId) {
      setSaveDefaultAddressDisabled(true)
      setSaveDefaultAddressChecked(true)
    } else {
      setSaveDefaultAddressDisabled(false)
      setSaveDefaultAddressChecked(false)
    }
  }, [formik.values.id, currentUser.defaultBrokerId])

  const handleContinueClick = () => {
    formik.handleSubmit()
    setAction("continue")
  }

  return (
    <SoeDrawerContainer>
      <SoeDrawerContent>
        <Typography variant="h5">{tOrderManagement("cardHeaders.broker")}:</Typography>
        <FullAddressForm
          formik={formik}
          displaySearchAddressBar
          displayEmailAddressField
          displaySaveAddressCheckbox={false}
          displaySaveDefaultAddressCheckbox={false}
          displayResidentialToggle={false}
          saveAddressCheckboxLabel={formik.values.id ? t("broker.updateAddressCheckbox.label") : t("broker.saveAddressCheckbox.label")}
          saveDefaultAddressCheckboxLabel={t("broker.saveDefaultAddressCheckbox.label")}
          saveDefaultAddressChecked={saveDefaultAddressChecked}
          setSaveDefaultAddressChecked={setSaveDefaultAddressChecked}
          saveDefaultAddressDisabled={saveDefaultAddressDisabled}
          addressType={AddressTypeEnum.Broker}
        />
      </SoeDrawerContent>
      <SoeDrawerActions
        buttons={
          !selectedQuotation.internationalOptions?.documentsOnly
            ? [
              {
                action: handleSaveAndCloseClick,
                label: t("drawer.broker.actions.prevButton.label", { ns: "rateRequest" }),
                variant: "outlined",
              },
              {
                action: handleContinueClick,
                label: t("drawer.broker.actions.nextButton.label", { ns: "rateRequest" }),
                variant: "contained",
              },
            ]
            : [
              {
                action: handleSaveAndCloseClick,
                label: t("drawer.broker.actions.prevButton.label", { ns: "rateRequest" }),
                variant: "outlined",
              },
            ]
        }
      />
    </SoeDrawerContainer>
  )
}

export default RateRequestBroker
