import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import Currency from "../currency"
import RateRequestSummaryCard from "../rate-request-summary-card"
import { IMPERIAL, METRIC } from "../../services/units-conversion"

import { CommercialInvoiceProps, PackagesProps } from "../../models/rate-request"
import { isCommercialInvoiceComplete, isWeightsMatching } from "../quotations/utils"
import { totalWeight } from "../../utils/packages"

function CommercialInvoiceCard({
   commercialInvoice,
   packages,
   setShowRateRequestDrawer,
   disabled,
   isEditable,
   isValid: customIsCommercialInvoiceComplete,
   ...sw
})
{
  const { t } = useTranslation("quotations")
  const theme = useTheme()

  const showRateRequestDrawer = () => {
    sw.stepWizard.goToNamedStep("commercial-invoice")
    setShowRateRequestDrawer(true)
  }

  // const summaryStatusProps = { variant: isCommercialInvoiceComplete(commercialInvoice) && isWeightsMatching(packages, commercialInvoice) ? "success" : "warning" }
  const summaryStatusProps = {
    variant: customIsCommercialInvoiceComplete !== undefined
      ? (customIsCommercialInvoiceComplete ? "success" : "warning")
      : (isCommercialInvoiceComplete(commercialInvoice) && isWeightsMatching(packages, commercialInvoice) ? "success" : "warning" )
  }


  const totalInvoiceItems = commercialInvoice?.invoiceItems?.length > 0 ? commercialInvoice.invoiceItems.reduce((accumulator, { quantity }) => accumulator + quantity, 0) : 0

  const totalAmount = commercialInvoice?.invoiceItems?.length > 0 ? commercialInvoice.invoiceItems.reduce((accumulator, { quantity, value }) => accumulator + quantity * value, 0) : 0

  return (
    !disabled && (
      <RateRequestSummaryCard title={t("commercialInvoiceTitle")} summaryStatusProps={summaryStatusProps} handleClick={showRateRequestDrawer} disabled={disabled} showStatus={isEditable} isClickable={isEditable}>
        {commercialInvoice?.invoiceItems?.length > 0 && (
          <Box mt={1} sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {t("commercialInvoice.totalItems")}
              {totalInvoiceItems}
            </Typography>
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {t("commercialInvoice.totalLines")}
              {commercialInvoice.invoiceItems.length}
            </Typography>
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {t("commercialInvoice.totalValue")}
              <Currency amount={totalAmount} currency={commercialInvoice.currency} currencyPosition="right" color={theme.palette.contentNeutralSecondary.main} showSymbol />
            </Typography>
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {t("packagesCard.totalWeight")}
              <Typography color={isWeightsMatching(packages, commercialInvoice) ? theme.palette.contentNeutralSecondary.main : "error"} fontWeight={isWeightsMatching(packages, commercialInvoice) ? "inherit" : "bold"} variant="bodySmall400">
                {totalWeight(commercialInvoice.invoiceItems)} {commercialInvoice.unitOfMeasure === IMPERIAL && "lb"} {commercialInvoice.unitOfMeasure === METRIC && "kg"}
              </Typography>
            </Typography>
          </Box>
        )}
      </RateRequestSummaryCard>
    )
  )
}

CommercialInvoiceCard.propTypes = {
  commercialInvoice: CommercialInvoiceProps,
  packages: PackagesProps,
  setShowRateRequestDrawer: PropTypes.func,
  disabled: PropTypes.bool,
  isEditable: PropTypes.bool,
  isValid: PropTypes.bool,
}

CommercialInvoiceCard.defaultProps = {
  commercialInvoice: undefined,
  packages: undefined,
  setShowRateRequestDrawer: () => {},
  disabled: false,
  isEditable: true,
  isValid: undefined,
}

export default CommercialInvoiceCard
