import React, { createContext, useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import getComparator from "../../utils/sort"
import searchQuotations from "../../utils/search"

const QuotationTableContext = createContext()

function QuotationTableProvider({ children }) {
  // TABLE DATA
  const [tableData, setTableData] = useState(undefined);

  // SELECTED QUOTATIONS
  const [selectedQuotations, setSelectedQuotations] = useState([])

  // QUOTED QUOTATIONS -> only use these in Validation/Create Label flow if unquoted quotations are selected + Bulk Create Label Action
  const selectedQuotationsWithQuotes = useMemo(() =>
      selectedQuotations.filter((quotation) => quotation.quote !== undefined),
    [selectedQuotations]
  );

  // PAGINATION
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [orderDirection, setOrderDirection] = useState("asc")
  const [orderBy, setOrderBy] = useState("orderId")
  const [sortedAndPaginatedData, setSortedAndPaginatedData] = useState([])


  // SEARCH STATE
  const [searchText, setSearchText] = useState("")
  const [searchField, setSearchField] = useState("orderId")



  // TABLE DATA FUNCTIONS
  const updateTableQuotation = (quotationData, shouldKeepQuote=false) => {
    // Update the table data
    setTableData((prevTableData) =>
      prevTableData.map((row) =>
        row.rateRequestId === quotationData.rateRequestId
          ? {
            ...row,
            ...quotationData,
            ...(shouldKeepQuote ? {} : { quote: undefined })
          }
          : row
      )
    );

    // Update the selected quotations if the updated quotation exists in selectedQuotations
    setSelectedQuotations((prevSelectedQuotations) =>
      prevSelectedQuotations.map((selectedQuotation) =>
        selectedQuotation.rateRequestId === quotationData.rateRequestId
          ? {
            ...selectedQuotation,
            ...quotationData,
            ...(shouldKeepQuote ? {} : { quote: undefined })
          }
          : selectedQuotation
      )
    );
  }

  const updateLoadingState = (quotationData, loadingType, isLoading) => {
    setTableData((prevTableData) =>
      prevTableData.map((row) =>
        row.rateRequestId === quotationData.rateRequestId
          ? { ...row, loading: { ...row.loading, [loadingType]: isLoading } }
          : row
      )
    );
  };

  // TODO: update this to use the updateTableQuotation as a "middleware"
  const setQuote = (quoteResponse) => {
    const rateRequestId = Object.keys(quoteResponse)[0]
    const quote = quoteResponse[rateRequestId]
    setTableData((prevTableData) =>
      prevTableData.map((row) =>
        row.rateRequestId === rateRequestId ? { ...row, quote } : row
      )
    );

    setSelectedQuotations((prevSelectedQuotations) =>
      prevSelectedQuotations.map((selectedQuotation) =>
        selectedQuotation.rateRequestId === rateRequestId ? { ...selectedQuotation, quote } : selectedQuotation
      )
    );
  }

  const deleteQuotation = ({ rateRequestId }) => {
    setTableData((prevTableData) =>
      prevTableData.filter((row) => row.rateRequestId !== rateRequestId)
    );
  }


  const updateErrorState = ({ quotation, errorType, isError }) => {
    setTableData((prevTableData) =>
      prevTableData.map((row) =>
        row.rateRequestId === quotation.rateRequestId
          ? { ...row, error: { ...row.error, [errorType]: isError } }
          : row
      )
    );
  }

  const addQuotation = (quotationData) => {
    setTableData((prevTableData) => {
      if (!prevTableData) return [quotationData];
      return [quotationData, ...prevTableData]
    })
  }


  // PAGINATION FUNCTIONS

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && orderDirection === "asc"
    setOrderDirection(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const selectAllQuotations = () => {
    // const quotationsToSelect = sortedAndPaginatedData.filter((row) => row.isComplete)
    setSelectedQuotations(sortedAndPaginatedData)
  }

  const updatedSelectedQuotations = (quotation) => {
    setSelectedQuotations((prevSelectedQuotations) => {
      const isAlreadySelected = prevSelectedQuotations.some(
        (selectedQuotation) => selectedQuotation.rateRequestId === quotation.rateRequestId
      );

      if (isAlreadySelected) {
        return prevSelectedQuotations.filter(
          (selectedQuotation) => selectedQuotation.rateRequestId !== quotation.rateRequestId
        );
      }
      return [...prevSelectedQuotations, quotation];
    });
  }

  const clearSelectedQuotations = () => {
    setSelectedQuotations([])
  }

  useEffect(() => {
    // First filter using existing search function
    const filteredData = searchText
      ? searchQuotations(tableData, searchField, searchText)
      : tableData;

    // If no data, reset sorted and paginated data
    if (!filteredData || filteredData.length === 0) {
      setSortedAndPaginatedData([]);
      setPage(1);
      return;
    }

    // Then sort and paginate
    const processed = filteredData
      .slice()
      .sort(getComparator(orderDirection, orderBy))
      .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage);

    setSortedAndPaginatedData(processed);

    // Reset to first page when search changes
    if (searchText) {
      setPage(1);
    }
  }, [tableData, page, rowsPerPage, orderDirection, orderBy, searchText, searchField]);

  const getTotalFilteredCount = () => {
    if (!tableData) return 0;
    if (!searchText) return tableData.length;
    return searchQuotations(tableData, searchField, searchText).length;
  };

  const value = useMemo(() => ({
      page,
      setPage,
      rowsPerPage,
      setRowsPerPage,
      orderDirection,
      setOrderDirection,
      setOrderBy,
      orderBy,
      handleRequestSort,
      sortedAndPaginatedData,
      selectAllQuotations,
      selectedQuotations,
      setSelectedQuotations,
      updatedSelectedQuotations,
      clearSelectedQuotations,
      tableData,
      setTableData,
      updateTableQuotation,
      updateLoadingState,
      setQuote,
      deleteQuotation,
      updateErrorState,
      addQuotation,
      searchText,
      setSearchText,
      searchField,
      setSearchField,
      selectedQuotationsWithQuotes,
      totalFilteredCount: getTotalFilteredCount()

  }), [
    page,
    rowsPerPage,
    orderDirection,
    orderBy,
    sortedAndPaginatedData,
    selectedQuotations,
    tableData,
    selectedQuotationsWithQuotes,
  ])


  return (
    <QuotationTableContext.Provider value={value}>
      {children}
    </QuotationTableContext.Provider>
  )
}

QuotationTableProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useQuotationTableContext = () => {
  return useContext(QuotationTableContext)
}

export default QuotationTableProvider
