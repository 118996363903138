import React from 'react';
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@mui/material"
import PropTypes from "prop-types"
import aggregateQuoteRates from "../../../../../../../../../utils/calculate"
import { pxToRem } from "../../../../../../../../../../../soe-theme/src/utils"
import Currency from "../../../../../../../../../../currency"

function TotalPrice({ quotes }) {
  const { t } = useTranslation("orderManagement")

  const totalPrices = aggregateQuoteRates(quotes.map((quotation)=>quotation.quote))
  const currencies = Object.keys(totalPrices)


  return (
    <Box sx={(theme)=>({ width: "100%",
      display: "flex",
      flexDirection: "column",
      padding: `${ pxToRem(20)} ${pxToRem(24)}`,
      borderBottomWidth: pxToRem(1),
      borderColor: "black",
    })}>
      {currencies.map((currency) => {
        return (
          <Box key={currency} style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}>
            <Typography variant="h5" textAlign="right">
              {t("modals.validation.total")}
            </Typography>
            <Typography variant="h5" textAlign="right">
              <Currency amount={totalPrices[currency].amount} currency={totalPrices[currency].currency} currencyPosition="right" showSymbol />
            </Typography>
          </Box>
          )
      })}
    </Box>
  )
}

TotalPrice.propTypes = {
  quotes: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
}


export default TotalPrice;
