import React from 'react';
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { Box, Button, Tooltip, Typography } from "@mui/material"

function CreateLabelButton({ onClick }) {
  const { t } = useTranslation("orderManagement")

  return (
    <Tooltip title={t("buttons.createLabel.tooltip")} placement="bottom">
      <Box style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Button onClick={onClick} style={{ backgroundColor: "green" }} fullWidth>
          <Typography>{t("buttons.createLabel.label")}</Typography>
        </Button>
      </Box>
    </Tooltip>
  )

}


CreateLabelButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default CreateLabelButton
