import React, { useState } from "react"
import { useCreateQuotationMutation } from "../../slice"
import { useQuotationContext } from "../../state/quotation-provider"
import { useQuotationTableContext } from "../../state/quotations-table-provider"
import DateTimeService from "../../../../services/date-time"

function createQuotation() {
  const [createQuotationMutation] = useCreateQuotationMutation()
  const [isCreateQuotationLoading, setIsCreateQuotationLoading] = useState(false)
  const {
    setAlert,
  } = useQuotationContext();

  const { addQuotation } = useQuotationTableContext()


  const newQuotation = async ({quotationData}) =>{
    setIsCreateQuotationLoading(true)
    try {
      const response = await createQuotationMutation({ payload: quotationData }).unwrap()
      if (response.error) {
        throw new Error("Unable to create quotation")
      }
      addQuotation({ orderId: quotationData.orderId, rateRequestId: response.rateRequestId, createdDate: DateTimeService.toISOString(Date.now()), shippingType: "cheapest" })
    } catch (err) {
      setAlert({
        severity: "error",
        message: "Error Creating quotation",
      })
    } finally {
      setIsCreateQuotationLoading(false)
    }
  }

  return { newQuotation, isCreateQuotationLoading }
}

export default createQuotation;
