import React from "react"
import { Box, Button, Tooltip, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"

function QuoteButton({ onClick, isQuoted }) {
  const { t } = useTranslation("orderManagement")

  return (
    <Tooltip title={isQuoted ? t("buttons.requote.tooltip") : t("buttons.getQuote.tooltip")} placement="top">
      <Box style={{width: "100%", display: "flex", justifyContent: "center"}}>
        <Button onClick={onClick} fullWidth>
          <Typography>{isQuoted ? t("buttons.requote.label") : t("buttons.getQuote.label")}</Typography>
        </Button>
      </Box>
    </Tooltip>
  )

}

QuoteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isQuoted: PropTypes.bool.isRequired,
}

export default QuoteButton
