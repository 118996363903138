import frLocale from "date-fns/locale/fr"
import enLocale from "date-fns/locale/en-US"

export const LOCALES = {
  fr: frLocale,
  en: enLocale,
}

export const LANGUAGES = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "fr",
    name: "Français",
  },
]

export const ROUTES = {
  HOME: "/",
  QUOTATIONS: "/quotations",
  ORDER_MANAGEMENT: "/order-management",
  RATE_REQUEST_HISTORY: "/quotations-history",
  SHIPMENTS: "/shipments",
  INVOICES: "/invoices",
  COMPANY: "/company",
  ADDRESS_BOOK: "address-book",
  USER_PROFILE: "/user-profile",
  GOOGLE_AUTH: "/googleauth",
  SIGN_UP: "/",
  LOG_IN: "/log-in",
  RESET_PASSWORD: "/reset-password",
  INVITATION: "/invitation/:invitationId",
  TEST: "/test",
}

export const NEW_APP_BAR_ROUTES = [ROUTES.SIGN_UP, ROUTES.LOG_IN]

export const FREIGHT_OPTIONS = {
  AFTER_HOURS: [
    {
      label: "delivery",
      value: "delivery",
    },
    {
      label: "pickup",
      value: "pickup",
    },
    {
      label: "both",
      value: "both",
    },
  ],
  TWO_PERSONS: [
    {
      label: "delivery",
      value: "delivery",
    },
    {
      label: "pickup",
      value: "pickup",
    },
    {
      label: "both",
      value: "both",
    },
  ],
  TAILGATE: [
    {
      label: "delivery",
      value: "delivery",
    },
    {
      label: "pickup",
      value: "pickup",
    },
    {
      label: "both",
      value: "both",
    },
  ],
}

export const PAYMENT_WIZARD_STEPS_ENUM = {
  PAYMENT_DETAILS: "payment-details",
  PAYMENT_VALIDATION: "payment-validation",
  PAYMENT_METHOD: "payment-method",
  PAYMENT_TRANSACTION: "payment-transaction",
}

// TODO: for now this is only used in the OrderManagement components, ideally should migrate all "BILL_TO_TYPES" to use this instead of redeclaring each time like in rate-request/billing-options
export const BILL_TO_TYPES = {
  myAccount: "myAccount",
  thirdParty: "thirdParty",
}

export const PAYMENT_MAX_CREDITCARDS = 3
