import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Box, Tooltip } from "@mui/material"
import Currency from "../../../../../../currency"
import CarrierServiceLogo from "../../../../../../carrier-service-logo"
import { pxToRem } from "../../../../../../../soe-theme/src/utils"
import SoeErrorOutlineIcon from "../../../../../../../soe-theme/src/icons/SoeErrorOutlineIcon"
import QuotePriceDetailsDrawer from "../../../../drawers/quote-price-details-drawer"

function QuoteInfo({ order }) {
  const { t } = useTranslation("orderManagement")
  const [showPaymentDetailsDrawer, setShowPaymentDetailsDrawer] = useState(false)
  const quote = order?.quote
  const error = order?.error?.quote

  if (error) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Tooltip title={t("errors.gettingQuote.label")} placement="top">
          <SoeErrorOutlineIcon color="error" />
        </Tooltip>
      </Box>
    )
  }

  if (!quote?.rate?.totals?.discounted?.total?.amount) {
    return null
  }

  const handleOnClick = (event) => {
    event.stopPropagation()
    // Only open drawer if click didn't come from within the drawer
    if (!showPaymentDetailsDrawer) {
      setShowPaymentDetailsDrawer(true)
    }
  }

  return (
    <Box
      onClick={handleOnClick}
      sx={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      gap: pxToRem(5),
      padding: "10%",
      cursor: "pointer", // Add pointer cursor
      "&:hover": {     // Add hover effect if desired
        borderRadius: pxToRem(6),
        backgroundColor: "rgba(183, 226, 237, 1)",
      }
    }}>
      <CarrierServiceLogo
        style={{
          height: "100%",
          width: "100%",
          display: "flex", // this style allows the CarrierServiceLogo to scale its size based on parent div
        }}
        code={quote.carrier.code}
        serviceName={quote.service.fullName}
        logo={quote.carrier.logo}
      />
      <Currency amount={quote.rate.totals.discounted.total.amount} currency={quote.rate.totals.discounted.total.currency} currencyPosition="right" showSymbol />
      <QuotePriceDetailsDrawer quote={order.quote} showPaymentDetailsDrawer={showPaymentDetailsDrawer} setShowPaymentDetailsDrawer={setShowPaymentDetailsDrawer} />
    </Box>
  )
}

QuoteInfo.propTypes = {
  order: PropTypes.instanceOf(Object),
}

QuoteInfo.defaultProps = {
  order: {}
}

export default QuoteInfo
