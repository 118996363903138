import React from "react"
import { Typography, TableRow, TableCell } from "@mui/material"
import PropTypes from "prop-types"
import CarrierDetailsCard from "../../../../../../../../carrier-details-card"
import Currency from "../../../../../../../../currency"

function QuoteRow({ quotation, removable }) {
  const { quote } = quotation

  return (
    <TableRow sx={{
      backgroundColor: "white",
    }}>
      {
        removable && (
          <TableCell>
          delete
        </TableCell>)
      }
      <TableCell>
        <Typography>
          {quotation.orderId}
        </Typography>
      </TableCell>
      <TableCell>
        <Currency amount={quote.rate.totals.discounted.total.amount} currency={quote.rate.totals.discounted.total.currency} currencyPosition="right" showSymbol />
      </TableCell>
      <TableCell>
        <CarrierDetailsCard
          service={quote.service}
          carrier={quote.carrier}
          commitment={quote.commitment}
          charges={quote.rate.charges}
          isBestPrice={quote.isBestPrice}
          isBestChoice={quote.isBestChoice}
          isFastDelivery={quote.isFastDelivery}
          isThirdParty={quote.isThirdParty}
        />
      </TableCell>
      <TableCell>
        <Typography variant="body2" color="textSecondary">
          {Object.values(quote.validationErrorMessages[0])[0]}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

QuoteRow.propTypes = {
  quotation: PropTypes.instanceOf(Object).isRequired,
  removable: PropTypes.bool,
}


QuoteRow.defaultProps = {
  removable: false,
};

export default QuoteRow
