import React from "react"
import { useTranslation } from "react-i18next"
import { Box, Button } from "@mui/material"
import { useQuotationTableContext } from "../../../../state/quotations-table-provider"

function SelectDeselectAllButtons() {
  const { selectAllQuotations, clearSelectedQuotations } = useQuotationTableContext()
  const {t} = useTranslation("orderManagement")

  const handleSelectAllChange = () => {
    selectAllQuotations()
  }
  const handleUnselectAllChange = () => {
    clearSelectedQuotations()
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Button variant="text" onClick={handleSelectAllChange}>
        {t("buttons.selectAll.label")}
      </Button>
      <Button variant="text" onClick={handleUnselectAllChange}>
        {t("buttons.unselectAll.label")}
      </Button>
    </Box>
  )
}

export default SelectDeselectAllButtons
