import React from "react"
import { useTranslation } from "react-i18next"
import { Box, TextField, MenuItem, Select, InputAdornment, Tooltip } from "@mui/material"
import { pxToRem } from "../../../../../../soe-theme/src/utils"
import { useQuotationTableContext } from "../../../../state/quotations-table-provider"

function SearchBar() {
  const {
    searchText,
    setSearchText,
    searchField,
    setSearchField
  } = useQuotationTableContext()
  const { t } = useTranslation("orderManagement")


  const searchFields = ["orderId", "shipper", "recipient", "createdDate"]

  return (
    <Box sx={{ height: "100%", display: "flex", alignSelf: "center" }}>
      <TextField
        size="medium"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        variant="outlined"
        label={t("searchbar.label")}
        placeholder={t(`searchbar.searchOptions.${searchField}.placeholder`)}
        sx={{ width: pxToRem(550) }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={t("searchbar.searchOptions.tooltip")} placement="right-end">
                <Select
                  size="small"
                  value={searchField}
                  onChange={(e) => setSearchField(e.target.value)}
                  variant="standard"
                  sx={{
                    '&:before': { borderBottom: 'none' },
                    '&:after': { borderBottom: 'none' },
                    '&:hover:not(.Mui-disabled):before': { borderBottom: 'none' },
                    '& .MuiSelect-select': {
                      py: 0.5,
                    },
                  }}
                >
                  {searchFields.map((item, idx) => (
                    <MenuItem value={item} key={idx}>
                      {t(`searchbar.searchOptions.${item}.label`)}
                    </MenuItem>
                  ))}
                </Select>
              </Tooltip>
            </InputAdornment>
          )
        }}
      />
    </Box>
  )
}

export default SearchBar
