import Box from "@mui/material/Box"

import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import RateRequestSummaryCard from "../rate-request-summary-card"

import { BuyerProps } from "../../models/rate-request"
import { isBuyerComplete } from "../quotations/utils"

function BuyerCard({
   buyer,
   setShowRateRequestDrawer,
   disabled,
   isEditable,
   showPhoneNumber,
   showEmailAddress,
   isValid: customIsBuyerComplete,
   ...sw })
{
  const theme = useTheme()
  const { t } = useTranslation("quotations")

  const showRateRequestDrawer = () => {
    sw.stepWizard.goToNamedStep("buyer")
    setShowRateRequestDrawer(true)
  }

  const summaryStatusProps = {
    variant: customIsBuyerComplete !== undefined
      ? (customIsBuyerComplete ? "success" : "warning")
      : (isBuyerComplete(buyer) ? "success" : "warning")
  }

  return (
    !disabled && (
      <RateRequestSummaryCard title={t("buyerTitle")} handleClick={showRateRequestDrawer} disabled={disabled} summaryStatusProps={summaryStatusProps} showStatus={isEditable} isClickable={isEditable}>
        {buyer && Object.keys(buyer).length > 0 && (
          <Box
            mt={1}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {buyer.companyName && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {buyer.companyName}
              </Typography>
            )}
            {buyer.personName && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {buyer.personName}
              </Typography>
            )}
            {buyer.addressLine1 && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {buyer.addressLine1}
              </Typography>
            )}
            {buyer.addressLine2 && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {buyer.addressLine2}
              </Typography>
            )}
            {(buyer.city || buyer.provinceCode) && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {`${buyer.city || ""}${buyer.city && buyer.provinceCode ? ", " : ""}${buyer.provinceCode || ""}${(buyer.city || buyer.provinceCode) && buyer.countryCode ? ", " : ""}${buyer.countryCode || ""}`}
              </Typography>
            )}
            {buyer.postalCode && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {buyer.postalCode}
              </Typography>
            )}
            {(showPhoneNumber || showEmailAddress) && (
              <Box
                mt={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {showPhoneNumber && buyer.phoneNumber && (
                  <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                    {buyer.phoneNumber}
                  </Typography>
                )}
                {showEmailAddress && buyer.emailAddress && (
                  <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                    {buyer.emailAddress}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        )}
      </RateRequestSummaryCard>
    )
  )
}

BuyerCard.propTypes = {
  buyer: BuyerProps,
  setShowRateRequestDrawer: PropTypes.func,
  disabled: PropTypes.bool,
  isEditable: PropTypes.bool,
  showPhoneNumber: PropTypes.bool,
  showEmailAddress: PropTypes.bool,
  isValid: PropTypes.bool,
}

BuyerCard.defaultProps = {
  buyer: undefined,
  setShowRateRequestDrawer: () => {},
  disabled: false,
  isEditable: true,
  showPhoneNumber: false,
  showEmailAddress: false,
  isValid: undefined,
}

export default BuyerCard
