import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { Route, Routes, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import App from "./components/app"
import AddressBook from "./components/address-book"
import Home from "./components/inex-home"
import SignUp from "./components/sign-up"
import ResetPassword from "./components/reset-password"
import Login from "./components/login"
import NotFound from "./components/not-found"
import Quotations from "./components/quotations"
import RateRequestHistory from "./components/rate-request-history"

import Invoices from "./components/invoices"
import Shipments from "./components/shipments"
import Company from "./components/company"
import Test from "./components/snippets"
import UserProfile from "./components/user-profile"
import SignUpInvitation from "./components/sign-up-invitation"

import { isDev } from "./utils/get-environment"
import { ROUTES } from "./utils/constants"
import { setShowAuthenticator } from "./reducers/authenticatorReducer"
import OrderManagement from "./components/order-management"

function AppRoutes() {
  const currentCompany = useSelector((state) => state.user?.company)
  return (
    <Routes>
      {/* Main routes of the app */}
      <Route path={ROUTES.HOME} element={<App />}>
        <Route index element={<ElementSwitcher onAuth={<Home />} onGuest={<SignUp />} />} />
        <Route
          path={ROUTES.SIGN_UP}
          element={
            <RequireGuest>
              <SignUp />
            </RequireGuest>
          }
        />
        <Route
          path={ROUTES.LOG_IN}
          element={
            <RequireGuest>
              <Login />
            </RequireGuest>
          }
        />
        <Route
          path={ROUTES.QUOTATIONS}
          element={
            <RequireAuth>
              <Quotations />
            </RequireAuth>
          }
        />
        {
          // (currentCompany?.featureFlags?.orderManagement === true) &&  // TODO: remove comment when I re-enable feature flag
          <Route
            path={ROUTES.ORDER_MANAGEMENT}
            element={
              <RequireAuth>
                <OrderManagement />
              </RequireAuth>
            }
          />
        }
        <Route
          path={ROUTES.ADDRESS_BOOK}
          element={
            <RequireAuth>
              <AddressBook />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.SHIPMENTS}
          element={
            <RequireAuth>
              <Shipments />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.INVOICES}
          element={
            <RequireAuth>
              <RequireAdminOrBilling>
                <Invoices />
              </RequireAdminOrBilling>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.COMPANY}
          element={
            <RequireAuth>
              <RequireAdmin>
                <Company />
              </RequireAdmin>
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.RATE_REQUEST_HISTORY}
          element={
            <RequireAuth>
              <RateRequestHistory />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.USER_PROFILE}
          element={
            <RequireAuth>
              <UserProfile />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.RESET_PASSWORD}
          element={
            <RequireGuest>
              <ResetPassword />
            </RequireGuest>
          }
        />
        <Route
          path={ROUTES.INVITATION}
          element={
            <RequireGuest>
              <SignUpInvitation />
            </RequireGuest>
          }
        />
        {/* Routes that must exist but leads nowhere */}
        <Route path={ROUTES.GOOGLE_AUTH} element={<Login />} />
        {/* Temporary Route for development purpose */}
        {isDev() && <Route path={ROUTES.TEST} element={<Test />} />}
        {/* Manage all other routes */}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}

function RequireAuth({ children }) {
  const dispatch = useDispatch()

  const currentUser = useSelector((state) => state.user)

  useEffect(() => {
    if (!currentUser.emailAddress) dispatch(setShowAuthenticator(true))
  }, [currentUser])

  return currentUser.emailAddress ? children : <div />
}

RequireAuth.propTypes = {
  children: PropTypes.node,
}

RequireAuth.defaultProps = {
  children: [],
}

function ElementSwitcher({ onAuth, onGuest }) {
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.user)

  useEffect(() => {
    if (!currentUser.emailAddress) dispatch(setShowAuthenticator(true))
  }, [currentUser])

  return currentUser.emailAddress ? onAuth : onGuest
}

ElementSwitcher.propTypes = {
  onAuth: PropTypes.node,
  onGuest: PropTypes.node,
}

ElementSwitcher.defaultProps = {
  onAuth: [],
  onGuest: [],
}

function RequireGuest({ children }) {
  const navigate = useNavigate()

  const currentUser = useSelector((state) => state.user)

  useEffect(() => {
    if (currentUser.emailAddress) navigate("/", { replace: true })
  }, [currentUser])

  return !currentUser.emailAddress ? children : <div />
}

RequireGuest.propTypes = {
  children: PropTypes.node,
}

RequireGuest.defaultProps = {
  children: [],
}

function RequireAdminOrBilling({ children }) {
  const navigate = useNavigate()

  const currentUser = useSelector((state) => state.user)

  useEffect(() => {
    if (currentUser.companyRole === 'SHIP') navigate("/", { replace: true })
  }, [currentUser])

  return currentUser.companyRole !== 'SHIP' ? children : <div />
}

RequireAdminOrBilling.propTypes = {
  children: PropTypes.node,
}

RequireAdminOrBilling.defaultProps = {
  children: [],
}

function RequireAdmin({ children }) {
  const navigate = useNavigate()

  const currentUser = useSelector((state) => state.user)

  useEffect(() => {
    if (currentUser.companyRole !== 'ADMIN') navigate("/", { replace: true })
  }, [currentUser])

  return currentUser.companyRole === 'ADMIN' ? children : <div />
}

RequireAdmin.propTypes = {
  children: PropTypes.node,
}

RequireAdmin.defaultProps = {
  children: [],
}

export default AppRoutes
