import { useTranslation } from "react-i18next"
import { useObtainQuotesMutation } from "../../slice"
import { useQuotationContext } from "../../state/quotation-provider"
import { useQuotationTableContext } from "../../state/quotations-table-provider"

function useGetQuote() {
  const { t } = useTranslation("orderManagement")
  const [getQuotes, { isLoading, error }] = useObtainQuotesMutation();

  const { setAlert } = useQuotationContext();
  const { setQuote, updateLoadingState, updateTableQuotation, updateErrorState } = useQuotationTableContext()

  const obtainQuote = async (order) => {
    updateTableQuotation(order) // invalidate the previous quote
    try {
      updateErrorState({ quotation: order, errorType: "quote", isError: false })
      updateLoadingState(order, "quote", true);
      const response = await getQuotes([order.rateRequestId]).unwrap();
      if (response.error) {
        throw new Error("Error getting quote")
      }
      setQuote(response);
    } catch (e) {
      updateErrorState({ quotation: order, errorType: "quote", isError: true }
      );
      setAlert({
        type: "quote",
        message: t("alert.error.quote.label"),
        severity: "error",
      })
    } finally {
      updateLoadingState(order, "quote", false);
    }
  };

  return { obtainQuote, isLoading, error };
}

export default useGetQuote
