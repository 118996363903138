import React from 'react';
import { Box, Typography } from "@mui/material"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { pxToRem } from "../../../../../../../soe-theme/src/utils"
import PrintLabels from "./components/print-labels"

function OrderGroup({ order }) {
  const { t } = useTranslation("printingQueue")

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', gap: pxToRem(5)}}>
      <Box sx={{display: "flex", flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {order.QMSOrderId && (
            <Typography sx={{ fontWeight: "bold" }}>
              {t("orderId")}: {order.QMSOrderId}
            </Typography>
          )}
          <Typography sx={{ fontWeight: "bold" }}>
            #{order.number}
          </Typography>
        </Box>

        <Typography>
          {order.quotation?.response?.service?.fullName?.en}
        </Typography>
      </Box>
      <PrintLabels key={order.id} orderId={order.id} />
    </Box>
  )
}
OrderGroup.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
}

export default OrderGroup
