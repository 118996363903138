import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import React, { useState } from "react"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import CompletedShipments from "./components/completed-shipments"
import DateRangeFilter from "../date-range-filter"
import InTransitShipments from "./components/in-transit-shipments"
import MoreMenu from "../more-menu"
import PendingShipments from "./components/pending-shipments"
import ShipmentsTabsStatus from "./constants"
import { pxToThemeSpacing } from "../../soe-theme/src/utils"
import PrintingQueue from "../printing-queue"

function Shipments() {
  const { t } = useTranslation("shipments")
  const theme = useTheme()

  const [shipmentTabsStatus, setShipmentTabsStatus] = useState(ShipmentsTabsStatus.Pending)
  const [dataRangeFilterValues, setDataRangeFilterValues] = useState()
  const [showManifestSearchDrawer, setShowManifestSearchDrawer] = useState(false)

  const handleManifest = () => {
    setShowManifestSearchDrawer(true)
  }

  const moreMenuOptionList = [
    {
      label: t("aShipmentDetails.optionsList.manifestSearch.label"),
      handleClick: handleManifest,
    },
  ]

  return (
    <Box component="div" className="shipments">
      <Card variant="outlined" square sx={{ height: "auto", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <CardHeader title={t("title")} style={{ pl: theme.spacing(4), pb: theme.spacing(2) }} />
        <PrintingQueue />
      </Card>
      <Card variant="outlined" square sx={{ height: "auto" }}>
        <Box component="div" sx={{ display: "inline-flex", justifyContent: "space-between", width: 0.5 }}>
          <Box component="div">
            <Tabs
              value={shipmentTabsStatus}
              style={{ minWidth: theme.utils.pxToRem(350) }}
              onChange={(_, value) => (value !== null ? setShipmentTabsStatus(value) : shipmentTabsStatus)}
              TabIndicatorProps={{
                style: {
                  backgroundColor: theme.palette.contentActionDefault.main,
                },
              }}
            >
              <Tab value={ShipmentsTabsStatus.Pending} label={t("shipmentStatus.pending.label")} sx={{ textTransform: "capitalize" }} />
              <Tab value={ShipmentsTabsStatus.InTransit} label={t("shipmentStatus.inTransit.label")} sx={{ textTransform: "capitalize" }} />
              <Tab value={ShipmentsTabsStatus.Completed} label={t("shipmentStatus.completed.label")} sx={{ textTransform: "capitalize" }} />
            </Tabs>
          </Box>
          <Box component="div">{shipmentTabsStatus !== ShipmentsTabsStatus.Pending && <DateRangeFilter setDataRangeFilterValues={setDataRangeFilterValues} />}</Box>
        </Box>
        <Box component="span" sx={{ display: "inline-flex", flexDirection: "row-reverse", width: 0.5, paddingRight: pxToThemeSpacing(30) }}>
          <MoreMenu optionsList={moreMenuOptionList} />
        </Box>
      </Card>
      {shipmentTabsStatus === ShipmentsTabsStatus.Pending && <PendingShipments showManifestSearchDrawer={showManifestSearchDrawer} setShowManifestSearchDrawer={setShowManifestSearchDrawer} />}
      {shipmentTabsStatus === ShipmentsTabsStatus.InTransit && <InTransitShipments dataRangeFilterValues={dataRangeFilterValues} />}
      {shipmentTabsStatus === ShipmentsTabsStatus.Completed && <CompletedShipments dataRangeFilterValues={dataRangeFilterValues} />}
    </Box>
  )
}
export default Shipments
