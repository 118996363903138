import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import PropTypes from "prop-types"
import React from "react"

import { useDispatch } from "react-redux"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import CarrierServiceLogo from "../../../carrier-service-logo"
import Currency from "../../../currency"
import DateTime from "../../../date-time"
import LogoText from "../../../../assets/images/business-logo-text.svg"
import TranslationService from "../../../../services/translation"

import { clearTransaction } from "../../../../reducers/transactionReducer"
import { clearPayment } from "../../../../reducers/paymentReducer"
import { updateQuote } from "../../../../reducers/quotationsReducer"

// Custom style
import { CustomCard, CustomCardContent, CustomChip, MuiWrapper } from "./style"
import AppConfig from "../../../../utils/app-config"

function Quote({ quote, setShowPaymentDrawer, ...muiBoxProps }) {
  const { t, i18n } = useTranslation("quotations")
  const dispatch = useDispatch()
  const theme = useTheme()

  const handleQuoteClick = () => {
    dispatch(clearPayment())
    dispatch(clearTransaction())
    // @todo: call backend to cancel paymentId
    dispatch(updateQuote(quote))
    setShowPaymentDrawer(true)
  }
  return (
    <MuiWrapper component="div" {...muiBoxProps}>
      <Box
        component="div"
        mt={theme.spacing(theme.utils.pxToThemeSpacing(4))}
        mb={theme.spacing(theme.utils.pxToThemeSpacing(8))}
        ml={theme.spacing(theme.utils.pxToThemeSpacing(8))}
        sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around" }}
      >
        <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          variant="outlined"
          onClick={handleQuoteClick}
        >
          <CustomCard>
            <CustomCardContent>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: theme.palette.backgroundNeutralGround.main }}>
                <CarrierServiceLogo code={quote.carrier.code} serviceName={quote.service.fullName} logo={quote.carrier.logo} align="center" />
                <Divider orientation="vertical" sx={{ minHeight: theme.utils.pxToRem(80), marginRight: theme.spacing(theme.utils.pxToThemeSpacing(16)) }} />
                <Box component="div" sx={{ display: "flex", flexDirection: "column", flexGrow: 2, alignContent: "flex-start" }}>
                  <Box component="div">
                    {quote.commitment.commitDate ? (
                      <DateTime date={quote.commitment.commitDate} time={quote.commitment.commitTime} timezoneCode={quote.commitment.timezoneCode} timezone={quote.commitment.timezone} />
                    ) : (
                      <Typography variant="inherit" component="span" sx={{ lineHeight: theme.utils.pxToRem(24) }}>
                        {t("quote.noDeliveryDate")}
                      </Typography>
                    )}
                  </Box>
                  {!quote.isThirdParty && (
                    <Box component="div" sx={{ display: "flex", minHeight: theme.utils.pxToRem(38) }}>
                      {quote?.service?.chips?.map((chip, index) => (
                        <CustomChip
                          key={`serviceChip${index}`}
                          sx={{
                            backgroundColor: theme.palette.contentAlertInformativeDarker.main,
                          }}
                          label={TranslationService.getLabelFromLocale(i18n.language, chip)}
                        />
                      ))}
                      {quote.rate.charges &&
                        quote.rate.charges.map((charge) => {
                          return (
                            charge.chips &&
                            charge.chips.map((chip, index) => (
                              <CustomChip
                                key={`chargeChip${index}`}
                                sx={{
                                  backgroundColor: "orange",
                                }}
                                label={TranslationService.getLabelFromLocale(i18n.language, chip)}
                              />
                            ))
                          )
                        })}
                      {quote.isBestPrice && <CustomChip label={t("quote.bestPrice", { ns: "quotations" })} />}
                      {quote.isBestChoice && <CustomChip label={t("quote.bestChoice", { ns: "quotations" })} />}
                      {quote.isFastDelivery && <CustomChip label={t("quote.fastDelivery", { ns: "quotations" })} />}
                    </Box>
                  )}
                </Box>
                {!quote.isThirdParty && (
                  <Box component="div" sx={{ display: "flex", flexDirection: "column" }}>
                    <Box component="div" sx={{ textAlign: "right", fontWeight: "bold", fontSize: theme.utils.pxToRem(28) }}>
                      <Currency amount={quote.rate.totals.discounted.total.amount} currency={quote.rate.totals.discounted.total.currency} currencyPosition="right" showSymbol />
                    </Box>
                    {AppConfig.features.showDiscountAmount && (
                      <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
                        <img src={LogoText} alt="Business logo" width={150} />
                        <Box component="span" sx={{ ml: 1 }}>
                          <Typography variant="inherit" component="span" mr={theme.spacing(theme.utils.pxToThemeSpacing(2))}>
                            {t("quote.discounted")}
                          </Typography>
                          <Currency amount={quote.rate.totals.retail.total.amount - quote.rate.totals.discounted.total.amount} currency={quote.rate.totals.discounted.total.currency} showSymbol={false} />
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </CustomCardContent>
          </CustomCard>
        </Box>
      </Box>
    </MuiWrapper>
  )
}

Quote.propTypes = {
  quote: PropTypes.shape({
    isBestChoice: PropTypes.bool,
    isBestPrice: PropTypes.bool,
    isFastDelivery: PropTypes.bool,
    isThirdParty: PropTypes.bool,
    carrier: PropTypes.shape({
      shortName: PropTypes.shape({}),
      fullName: PropTypes.shape({}),
      logo: PropTypes.string,
      code: PropTypes.string,
    }),
    service: PropTypes.shape({
      shortName: PropTypes.shape({}),
      fullName: PropTypes.shape({}),
      code: PropTypes.string,
      chips: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    commitment: PropTypes.shape({
      commitDate: PropTypes.string,
      commitTime: PropTypes.string,
      timezone: PropTypes.string,
      timezoneCode: PropTypes.string,
      guaranteed: PropTypes.bool,
    }),
    chips: PropTypes.arrayOf(PropTypes.shape({})),
    rate: PropTypes.shape({
      charges: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          type: PropTypes.string,
          subtype: PropTypes.string,
          retail: PropTypes.shape({
            amount: PropTypes.number,
            currency: PropTypes.string,
          }),
          discounted: PropTypes.shape({
            amount: PropTypes.number,
            currency: PropTypes.string,
          }),
        })
      ),
      totals: PropTypes.shape({
        discounted: PropTypes.shape({
          subtotal: PropTypes.shape({
            amount: PropTypes.number,
            currency: PropTypes.string,
          }),
          tax: PropTypes.shape({
            amount: PropTypes.number,
            currency: PropTypes.string,
          }),
          total: PropTypes.shape({
            amount: PropTypes.number,
            currency: PropTypes.string,
          }),
        }),
        retail: PropTypes.shape({
          subtotal: PropTypes.shape({
            amount: PropTypes.number,
            currency: PropTypes.string,
          }),
          tax: PropTypes.shape({
            amount: PropTypes.number,
            currency: PropTypes.string,
          }),
          total: PropTypes.shape({
            amount: PropTypes.number,
            currency: PropTypes.string,
          }),
        }),
      }),
    }),
  }).isRequired,
  setShowPaymentDrawer: PropTypes.func,
}

Quote.defaultProps = {
  setShowPaymentDrawer: () => {},
}

export default Quote
