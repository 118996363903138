import React, { useState } from "react"
import PropTypes from "prop-types"
import Dialog from "@mui/material/Dialog"
import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import Typography from "@mui/material/Typography"
import { pxToRem } from "../../soe-theme/src/utils"

function ConfirmationModal({ open, title, handleClose, onConfirm, cancelText, confirmText, contentText }) {

  const [isActionLoading, setIsActionLoading] = useState(false)
  const handleCancel = (event)=> {
    event.stopPropagation()
    handleClose()
  }

  const handleConfirm = async (event)=> {
    setIsActionLoading(true)
    event.stopPropagation()
    await onConfirm()
    setIsActionLoading(false)
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={isActionLoading ? undefined : handleCancel}
      disableEscapeKeyDown={isActionLoading}
      PaperProps={{
        sx: {
          minWidth: {
            "md": pxToRem(500),
          }
        }
      }}
    >
      <DialogTitle>
        <Typography variant="headingH6" sx={(theme) => ({ color: theme.palette.contentNeutralPrimary.main, fontWeight: theme.typography.fontWeightBold })}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box style={{ width:'100%', display: "flex", flexDirection: "row", justifyContent: "space-between", padding: `${pxToRem(5)} ${pxToRem(20)}` }}>
          <Button onClick={handleCancel} color="secondary" disabled={isActionLoading}>
            {cancelText}
          </Button>
          <Button onClick={handleConfirm} color="secondary" disabled={isActionLoading}>
            {confirmText}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  contentText: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
}

ConfirmationModal.defaultProps = {
  title: "",
  cancelText: "Cancel",
  confirmText: "Confirm",
  contentText: "",
}

export default ConfirmationModal
