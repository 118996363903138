import React from "react";
import { Button, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { pxToRem } from "../../../../../../soe-theme/src/utils"

function ImportCSVButton({ setShowFileUploadDrawer }) {
  const { t } = useTranslation("orderManagement")

  return (
    <Button size="medium" variant="text" onClick={() => setShowFileUploadDrawer(true)} sx={{ height: "100%", maxHeight: pxToRem(40)}}>
      <Typography>{t("buttons.importOrders.label")}</Typography>
    </Button>
  )
}

ImportCSVButton.propTypes = {
  setShowFileUploadDrawer: PropTypes.func.isRequired,
}

export default ImportCSVButton
