import React from 'react'
import { Box } from "@mui/material"
import QuotesList from "../../../quotes-list"
import ButtonsRow from "./components/buttons-row"
import { useQuotationTableContext } from "../../../../../../../state/quotations-table-provider"

function QuotesValidation() {

  const { selectedQuotationsWithQuotes, updatedSelectedQuotations } = useQuotationTableContext()

  const handleRemoveQuotation = (quotation) => {
    updatedSelectedQuotations(quotation)
  }

  return (
    <Box style={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between" }}>
      <Box sx={{ overflowY: "auto", flexGrow: 1, width: "100%" }}>
        <QuotesList quotes={selectedQuotationsWithQuotes} removable onRemove={handleRemoveQuotation} />
      </Box>
      <ButtonsRow />
    </Box>
  )
}

export default QuotesValidation
