import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"
import { pxToRem } from "../../../../../../soe-theme/src/utils"
import BulkActionDropdown from "./components/bulk-action-dropdown"
import BulkActionExecuteButton from "./components/bulk-action-execute-button"
import useGetQuote from "../../../../hooks/get-quote"
import { useQuotationContext } from "../../../../state/quotation-provider"
import useDeleteQuote from "../../../../hooks/delete-quote"
import { isAnySelectedQuotationsQuoted } from "../../../../utils/quotes"
import ConfirmationModal from "../../../../../confirmation-modal"
import { useQuotationTableContext } from "../../../../state/quotations-table-provider"

function BulkActionsContainer() {
  const [curBulkAction, setCurBulkAction] = useState("quote")
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isBulkActionLoading, setIsBulkActionLoading] = useState(false)
  const { deleteQuoteMutation } = useDeleteQuote()

  const { t } = useTranslation("orderManagement")
  const { obtainQuote } = useGetQuote()

  const { setShowValidateCreateLabelModal } = useQuotationContext()
  const { selectedQuotations } = useQuotationTableContext()
  const selectedQuotationIds = selectedQuotations.map(quotation => quotation.orderId).join(", ")


  const executeDeleteQuotations = async () => {
    await Promise.all(selectedQuotations.map((item) => deleteQuoteMutation(item)))
  }

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false)
  }

  const doBulkFunction = async (bulkAction) => {
    setIsBulkActionLoading(true)
    try {
      if (bulkAction === "quote") {
        await Promise.all(
          selectedQuotations
            .filter((quotation) => quotation.isComplete === true) // this allows us to select all (even invalid quotations), and can do "quote" on them all but only the complete ones will fetch a quote
            .map((quotation) => obtainQuote(quotation))
        )
      } else if (bulkAction === "delete") {
        setIsConfirmationModalOpen(true)
      } else if (bulkAction === "validateCreateLabel") {
        setShowValidateCreateLabelModal(true)
      }
    } catch (error) {
      console.error("Bulk action failed:", error)
    } finally {
      setIsBulkActionLoading(false)
    }
  }

  const shouldButtonBeEnabled = () => {
    if (selectedQuotations.length === 0) return false
    if (curBulkAction === "quote") {
      return true
    }
    if (curBulkAction === "delete") {
      return true
    }
    if (curBulkAction === "validateCreateLabel") {
      return isAnySelectedQuotationsQuoted(selectedQuotations)
    }
    return false
  }

  const handleButtonClick = async () => {
    await doBulkFunction(curBulkAction)
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: pxToRem(10), paddingRight: pxToRem(20), justifyContent: "center", alignItems: "center", marginTop: "auto" }}>
      <BulkActionDropdown curBulkAction={curBulkAction} setCurBulkAction={setCurBulkAction} />
      <BulkActionExecuteButton curBulkAction={curBulkAction} onClick={handleButtonClick} isLoading={isBulkActionLoading} disabled={!shouldButtonBeEnabled()} />
      <ConfirmationModal
        open={isConfirmationModalOpen}
        handleClose={handleCloseConfirmationModal}
        onConfirm={executeDeleteQuotations}
        title={t("dialog.delete.title.label")}
        cancelText={t("dialog.bulkDelete.buttons.cancel.label")}
        confirmText={t("dialog.bulkDelete.buttons.confirm.label")}
        contentText={`${t("dialog.bulkDelete.contentText.label")} ${selectedQuotationIds}`}
      />
    </Box>
  )
}

export default BulkActionsContainer
