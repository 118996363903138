// React
import PropTypes from "prop-types"
import React from "react"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Others

// Custom style
import SummaryStatus from "../../../../../../../../../../summary-card/components/summary-status"
import MuiWrapper from "../../../../../../../../../../main/style"
import { CustomCard, CustomCardContent } from "../../../../../../../../../../summary-card/style"
import { pxToRem } from "../../../../../../../../../../../soe-theme/src/utils"

function SummaryCard({ title, children, summaryStatusProps, handleClick, disabled, showStatus, isClickable, ...muiBoxProps }) {
  const customCardOnClick = (event) => {
    if (disabled) event.preventDefault()
    if (handleClick && !disabled === true) handleClick(event)
  }

  return (
    <MuiWrapper {...muiBoxProps}>
      <CustomCard onClick={isClickable ? customCardOnClick : undefined} disabled={disabled} isclickable={isClickable.toString()}>
        {/* <CustomCardContent style={{padding: pxToRem(10)}}> */}
        <CustomCardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="bodyMedium600">{title}</Typography>
            {!disabled && showStatus && summaryStatusProps && <SummaryStatus {...summaryStatusProps} />}
          </Box>
          {children}
        </CustomCardContent>
      </CustomCard>
    </MuiWrapper>
  )
}

SummaryCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  summaryStatusProps: PropTypes.shape({
    variant: PropTypes.string,
  }),
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
  showStatus: PropTypes.bool,
  isClickable: PropTypes.bool,
}

SummaryCard.defaultProps = {
  title: undefined,
  summaryStatusProps: undefined,
  children: undefined,
  disabled: false,
  showStatus: true,
  isClickable: true,
  handleClick: () => {},
}

export default SummaryCard
