import React from "react"
import { useTranslation } from "react-i18next"
import Papa from 'papaparse'
import { Button, Typography } from "@mui/material"
import { pxToRem } from "../../../../../../soe-theme/src/utils"

function DownloadTemplateButton() {
  const {t} = useTranslation("orderManagement")


  const downloadTemplate = () => {
    const instructionRows = t("csvTemplate.templateInstructions", { returnObjects: true }).map(instruction => [instruction])

    const templateHeaders = Object.keys(t("csvTemplate.headerDetails", { returnObjects: true }))

    const headerDescriptions = templateHeaders.map(header =>
      t(`csvTemplate.headerDetails.${header}`) || ''
    )

    // Looks better without the "Add data here" text
    // // Prepare empty row
    // const firstRow = t("csvTemplate.firstRow", { returnObjects: true }).concat(
    //   Array(templateHeaders.length - 1).fill("")
    // )

    const emptyRow = Array(templateHeaders.length).fill("")

    const csvContent = [
      ...instructionRows,
      headerDescriptions,
      emptyRow,
      templateHeaders,
      emptyRow,
    ]

    const csv = Papa.unparse(csvContent)

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', 'order-management-template.csv')
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Button size="medium" variant="text" onClick={downloadTemplate} sx={{ height: "100%", maxHeight: pxToRem(40)}}>
      <Typography>{t("buttons.downloadTemplate.label")}</Typography>
    </Button>
  )

}

export default DownloadTemplateButton
