import { useTranslation } from "react-i18next"
import { useOnAccountPaymentMutation, useUpdateQuotationMutation } from "../../slice"
import { useValidationContext } from "../../components/modals/validate-create-label-modal/state/provider"
import { useQuotationTableContext } from "../../state/quotations-table-provider"

// TODO: maybe this should be separated in 2 separate hooks, one for payments and one for UI/state changes
function useOnAccountPayment() {
  const { t } = useTranslation("orderManagement")
  const [placeOrder, { isLoading: isPlacingOrderLoading, error: placeOrderError }] = useOnAccountPaymentMutation();
  const [ updateQuotationMutation ] = useUpdateQuotationMutation()

  const {
    deleteQuotation,
  } = useQuotationTableContext();
  const {
    setIsPaymentLoading,
    validatedQuotations,
    setSuccessfullyPaidQuotations,
    setUnsuccessfullyPaidQuotations,
    setModalAlert,
  } = useValidationContext()

  const createPaymentError = (message, rateRequestId) => {
    const error = new Error(message);
    error.rateRequestId = rateRequestId;
    return error;
  };

  const makeOnAccountPayment = async (order) => {
    // for testing
    // if (order.id === 5) {
    //   throw createPaymentError("payment failed", order.rateRequestId);
    // }

    const response = await placeOrder({
      payload: {
        rateResponseId: order.quote.rateResponseId,
        skipPayment: "true",
        rateRequestId: order.rateRequestId,
      },
    }).unwrap() // TODO: do I need to unwrap?

    if (response.message !== "OK") {
      throw createPaymentError("payment failed", order.rateRequestId);
    }

    return {
      ...response,
      rateRequestId: order.rateRequestId,
    }
  }

  const processPayments = async () => {
    try {
      setIsPaymentLoading(true)

      // Process payments
      const paymentResults = await Promise.allSettled(
        validatedQuotations.map(quotation => makeOnAccountPayment(quotation))
      );
      const successfulPayments = paymentResults
        .filter(result => result.status === 'fulfilled')
        .map(result => result.value);

      const failedPayments = paymentResults
        .filter(result => result.status === 'rejected')
        .map(result => ({
          message: result.reason.message,
          rateRequestId: result.reason.rateRequestId
        }));

      // FE: optimistic update
      await Promise.allSettled(
        successfulPayments.map(result =>
          deleteQuotation({ rateRequestId: result.rateRequestId })
        )
      );

      const successfulQuotations = validatedQuotations.filter(quotation =>
        successfulPayments.some(payment => payment.rateRequestId === quotation.rateRequestId)
      );

      setSuccessfullyPaidQuotations(successfulQuotations);

      const failedQuotations = validatedQuotations.filter(quotation =>
        failedPayments.some(payment => payment.rateRequestId === quotation.rateRequestId)
      );

      setUnsuccessfullyPaidQuotations(failedQuotations);


      // Handle success/failure alerts
      if (failedPayments.length > 0) {
        setModalAlert({
          severity: 'error',
          message: t("alert.error.payment.label")
        });
      } else {
        setModalAlert({
          severity: 'success',
          message: "All payments succeeded"
        });
      }

      return paymentResults;
    } catch (error) {
      return setModalAlert({
        severity: 'error',
        message: "Error processing payments",
      });
    } finally {
      setIsPaymentLoading(false)
    }
  }

  return {
    processPayments,
    isPlacingOrderLoading,
    placeOrderError
  }
}

export default useOnAccountPayment;