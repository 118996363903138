import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDeleteQuotationMutation } from "../../slice"
import { useQuotationContext } from "../../state/quotation-provider"
import { useQuotationTableContext } from "../../state/quotations-table-provider"

function useDeleteQuote() {
  const [deleteQuotationMutation, { isLoading, error }] = useDeleteQuotationMutation()
  const { setAlert } = useQuotationContext();
  const { updateLoadingState, deleteQuotation, updateErrorState } = useQuotationTableContext()
  const { t } = useTranslation("orderManagement")
  const [isComponentMounted, setIsComponentMounted] = useState(true);

  useEffect(() => { // Cleanup: prevent memory leak
    setIsComponentMounted(true);
    return () => {
      setIsComponentMounted(false);
    };
  }, []);

  const deleteQuoteMutation = async (order) => {
    try {
      updateLoadingState(order, "delete", true);
      updateErrorState({ quotation: order, errorType: "delete", isError: false })
      const response = await deleteQuotationMutation(order.rateRequestId)
      if (response.error) {
        throw new Error("Error getting quote")
      }
      deleteQuotation({ rateRequestId: order.rateRequestId });
      setAlert({
        type: "delete",
        message: t("alert.success.delete.label"),
        severity: "success",
      })
    } catch (e) {
      updateErrorState({ quotation: order, errorType: "delete", isError: true })
      setAlert({
        type: "delete",
        message: t("alert.error.delete.label"),
        severity: "error",
      })
    } finally {
      // updateLoadingState(order, "delete", false);
      if (isComponentMounted) {
        updateLoadingState(order, "delete", false);
      }
    }
  }

  return { deleteQuoteMutation, isLoading, error };
}

export default useDeleteQuote
