import React from "react"
import { useSelector } from "react-redux"
import { Box, Grid } from "@mui/material"
import { useQuotationContext } from "../../../../../state/quotation-provider"
import ButtonsRow from "../buttons/bottom-buttons-row"
import { pxToRem } from "../../../../../../../soe-theme/src/utils"
import SummaryCardGrid from "./components/summary-card-grid"
import ShippingOptionsCard from "../../../../../../shipping-options-card"
import RecipientCard from "../../../../../../recipient-card"
import ShipperCard from "../../../../../../shipper-card"
import PackagesCard from "../../../../../../packages-card"
import BillingOptionsCard from "../../../../../../billing-options-card"
import { useGetCompanyProfileQuery } from "../../../../../../../services/company/slice"
import { isFreightOptionsRequired, isInternationalShipment } from "../../../../../../quotations/utils"
import FreightOptionsCard from "../../../../../../freight-options-card"
import InternationalOptionsCard from "../../../../../../international-options-card"
import BrokerCard from "../../../../../../broker-card"
import CommercialInvoiceCard from "../../../../../../commercial-invoice-card"
import BuyerCard from "../../../../../../buyer-card"
import useOrderValidation from "../../../../../hooks/use-order-validation"
import useUpdateQuotation from "../../../../../hooks/update-quote"
import OrderIdTextField from "../order-id-text-field"

function OrderSummaryCards() {
  const { selectedQuotation, setShowRateRequestForm, rateRequestStepWizard, closeRateRequestModal, setOrderId } = useQuotationContext()
  const {
    shipperComplete,
    recipientComplete,
    packagesComplete,
    shippingOptionsComplete,
    billingOptionsComplete,
    freightOptionsComplete,
    internationalOptionsComplete ,
    buyerComplete,
    commercialInvoiceComplete,
    brokerComplete,
    isOrderComplete
  } = useOrderValidation(selectedQuotation)


  const { updateQuotation, isLoading: isMutating } = useUpdateQuotation()

  const currentUser = useSelector((state) => state.user)
  const { data: currentCompany } = useGetCompanyProfileQuery(currentUser.companyId)

  const handleSaveQuotation = async () => {
    await updateQuotation({ order: { ...selectedQuotation, orderId: selectedQuotation.orderId }, isOrderComplete })
    closeRateRequestModal()
  }

  const handleCancel = () => {
    closeRateRequestModal()
  }

  return (
    <Box style={{ display: "flex", flexDirection: "column", height: "100%", overflow: "hidden" }}>
      <Box sx={{ flex: 1, overflowY: "auto" }}>
        <Box style={{ maxWidth: pxToRem(300), padding: `${pxToRem(20)} ${pxToRem(40)}`}}>
          <OrderIdTextField orderId={selectedQuotation.orderId} setOrderId={setOrderId} />
        </Box>
        <Grid container spacing={2} style={{ padding: `0 ${pxToRem(40)} ${pxToRem(20)}`}}>
          <SummaryCardGrid>
            <ShipperCard
              shipper={selectedQuotation.shipper}
              stepWizard={rateRequestStepWizard}
              setShowRateRequestDrawer={setShowRateRequestForm}
              showEmailAddress
              showPhoneNumber
              isValid={shipperComplete}
            />
          </SummaryCardGrid>
          <SummaryCardGrid>
            <RecipientCard
              recipient={selectedQuotation.recipient}
              setShowRateRequestDrawer={setShowRateRequestForm}
              stepWizard={rateRequestStepWizard}
              showEmailAddress
              showPhoneNumber
              isValid={recipientComplete}
            />
          </SummaryCardGrid>
          <SummaryCardGrid>
            <PackagesCard
              packages={selectedQuotation.packages}
              commercialInvoice={selectedQuotation.commercialInvoice}
              setShowRateRequestDrawer={setShowRateRequestForm}
              stepWizard={rateRequestStepWizard}
              isValid={packagesComplete} />
          </SummaryCardGrid>
          <SummaryCardGrid>
            <ShippingOptionsCard
              shippingOptions={selectedQuotation.shippingOptions}
              setShowRateRequestDrawer={setShowRateRequestForm}
              stepWizard={rateRequestStepWizard}
              isValid={shippingOptionsComplete}
            />
          </SummaryCardGrid>
          <SummaryCardGrid>
            {(currentCompany?.featureFlags?.thirdPartyBilling === true)
              && <BillingOptionsCard
                billingOptions={selectedQuotation.billingOptions}
                setShowRateRequestDrawer={setShowRateRequestForm}
                stepWizard={rateRequestStepWizard}
                isValid={billingOptionsComplete}
              />}
          </SummaryCardGrid>
          {isFreightOptionsRequired(selectedQuotation.packages) &&
            <SummaryCardGrid>
              <FreightOptionsCard
                freightOptions={selectedQuotation.freightOptions}
                setShowRateRequestDrawer={setShowRateRequestForm}
                disabled={!isInternationalShipment(selectedQuotation.shipper, selectedQuotation.recipient)}
                stepWizard={rateRequestStepWizard}
                isValid={freightOptionsComplete}
              />
            </SummaryCardGrid>
          }
          <SummaryCardGrid>
            <InternationalOptionsCard
              internationalOptions={selectedQuotation.internationalOptions}
              setShowRateRequestDrawer={setShowRateRequestForm}
              disabled={!isInternationalShipment(selectedQuotation.shipper, selectedQuotation.recipient)}
              stepWizard={rateRequestStepWizard}
              isValid={internationalOptionsComplete}
            />
          </SummaryCardGrid>
          <SummaryCardGrid>
            <BrokerCard
              broker={selectedQuotation.broker}
              setShowRateRequestDrawer={setShowRateRequestForm}
              disabled={!selectedQuotation.internationalOptions?.brokerRequired}
              stepWizard={rateRequestStepWizard}
              showEmailAddress
              showPhoneNumber
              isValid={brokerComplete}
            />
          </SummaryCardGrid>
          <SummaryCardGrid>
            <CommercialInvoiceCard
              commercialInvoice={selectedQuotation.commercialInvoice}
              packages={selectedQuotation.packages}
              setShowRateRequestDrawer={setShowRateRequestForm}
              disabled={selectedQuotation.internationalOptions?.documentsOnly || !isInternationalShipment(selectedQuotation.shipper, selectedQuotation.recipient)}
              stepWizard={rateRequestStepWizard}
              isValid={commercialInvoiceComplete}
            />
          </SummaryCardGrid>
          <SummaryCardGrid>
            <BuyerCard
              buyer={selectedQuotation.buyer}
              setShowRateRequestDrawer={setShowRateRequestForm}
              disabled={!selectedQuotation.commercialInvoice?.buyerRequired}
              stepWizard={rateRequestStepWizard}
              showEmailAddress
              showPhoneNumber
              isValid={buyerComplete}
            />
          </SummaryCardGrid>
        </Grid>
      </Box>
      <Box sx={{ position: "sticky", bottom: 0, zIndex: 1 }}>
        <ButtonsRow onSave={handleSaveQuotation} onCancel={handleCancel} isLoading={isMutating} />
      </Box>
    </Box>

  )
}

export default OrderSummaryCards
