import React, { useState } from "react"
import { Box, Card } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import ImportCSVButton from "./components/import-csv-button"
import BulkActionsContainer from "./components/bulk-actions-container"
import FileUploadDrawer from "../drawers/file-upload-drawer"
import SelectDeselectAllButtons from "./components/select-deselect-all-buttons"
import NewQuotationButton from "./components/new-quotation-button"
import DownloadTemplateButton from "./components/download-template-button"
import SearchBar from "./components/search-bar"
import { pxToRem } from "../../../../soe-theme/src/utils"

function HeaderButtons() {
  const [showFileUploadDrawer, setShowFileUploadDrawer] = useState(false);

  const theme = useTheme()

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
        gap: pxToRem(15),
        overflow: "visible",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignContent: "center", height: "100%" }}>
        <Box sx={{ display: "flex", flexDirection: "row", gap: theme.spacing(1) }}>
          <NewQuotationButton />
          <SelectDeselectAllButtons />
          <DownloadTemplateButton />
          <ImportCSVButton setShowFileUploadDrawer={setShowFileUploadDrawer} />
        </Box>
        <BulkActionsContainer />
      </Box>
      <Box sx={{height: "100%", width: "100%", display: "flex", justifyContent: "center"}}>
        <SearchBar />
      </Box>
      <FileUploadDrawer setShowFileUploadDrawer={setShowFileUploadDrawer} showFileUploadDrawer={showFileUploadDrawer} />
    </Card>
  )
}

export default HeaderButtons;
