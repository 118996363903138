import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, CircularProgress, Tooltip } from "@mui/material"
import PropTypes from "prop-types"
import SoeDeleteOutlineIcon from "../../../../../../../../soe-theme/src/icons/SoeDeleteOutlineIcon"
import { pxToRem } from "../../../../../../../../soe-theme/src/utils"
import useDeleteQuote from "../../../../../../hooks/delete-quote"
import ConfirmationModal from "../../../../../../../confirmation-modal"

function DeleteQuotationButton({ order }) {

  const { t } = useTranslation("orderManagement")
  const isLoading = order.loading?.delete
  const { deleteQuoteMutation } = useDeleteQuote()
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false)
  }

  // the alert logic happens in the hook
  const handleDeleteQuotation = () => {
    deleteQuoteMutation(order) // don't await or else memory leak since component will unmount
  }

  const handleOnClick = async (event) => {
    event.stopPropagation()
    setIsConfirmationModalOpen(true)
  }

  return (
    <>
      <Tooltip title={t("buttons.deleteQuotation.tooltip")}>
        <Box onClick={handleOnClick} sx={{ display: "flex", justifyContent: "center", alignItems: "center",
          "&:hover": {
            cursor: "pointer",
          }
        }}
        >
          {
            isLoading
              ?
              <CircularProgress size={pxToRem(20)} sx={(theme) => ({ color: theme.palette.secondary.main })} />
              :
              <SoeDeleteOutlineIcon />
          }
        </Box>
      </Tooltip>
      <ConfirmationModal
        handleClose={handleCloseConfirmationModal}
        onConfirm={handleDeleteQuotation}
        open={isConfirmationModalOpen}
        title={t("dialog.delete.title.label")}
        cancelText={t("dialog.delete.buttons.cancel.label")}
        confirmText={t("dialog.delete.buttons.confirm.label")}
        contentText={t("dialog.delete.contentText.label")}
      />
    </>
  )
}

DeleteQuotationButton.propTypes = {
  order: PropTypes.instanceOf(Object).isRequired,
}

export default DeleteQuotationButton
