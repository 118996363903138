import React, { useEffect } from "react"
import { Paper, Table, TableBody, TableContainer, TableRow, TableCell, Skeleton } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import EnhancedTableHead from "../../../enhanced-table-head"
import OrderRow from "./order-row"
import { useQuotationContext } from "../../state/quotation-provider"
import { useGetQuotationsQuery } from "../../slice"
import CustomPagination from "../../../custom-pagination"
import { useQuotationTableContext } from "../../state/quotations-table-provider"

function QuotationsTable() {
  const { setQuotationOpenModal } = useQuotationContext()
  const {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    orderDirection,
    orderBy,
    handleRequestSort,
    sortedAndPaginatedData,
    tableData,
    setTableData,
    totalFilteredCount,
  } = useQuotationTableContext()
  const { data: quotations, isLoading: isFetching } = useGetQuotationsQuery({skip: (tableData !== undefined)}) // only query on first render when tableData is initially undefined
  const theme = useTheme()
  const { t } = useTranslation("orderManagement")

  useEffect(() => {
    if (tableData !== undefined) return // this prevents the table from refetching data. Very important or else it overwrites local State (ex: quote, loading, error, etc.)
    setTableData(quotations)
  }, [quotations])

  const headCells = [
    {
      id: "selected",
      disablePadding: false,
      label: "",
    },
    {
      id: "orderId",
      disablePadding: false,
      label: t("table.headers.orderId"),
    },
    {
      id: "shipper",
      disablePadding: false,
      label: t("table.headers.shipper"),
      hideSortIcon: true,
    },
    {
      id: "recipient",
      disablePadding: false,
      label: t("table.headers.recipient"),
      hideSortIcon: true,
    },
    {
      id: "pieces",
      disablePadding: false,
      label: t("table.headers.pieces"),
      hideSortIcon: true,
    },
    {
      id: "weight",
      disablePadding: false,
      label: t("table.headers.weight"),
      hideSortIcon: true,
    },
    {
      id: "createdDate",
      disablePadding: false,
      label: t("table.headers.createdDate"),
    },
    {
      id: "carrierTag",
      disablePadding: false,
      label: t("table.headers.carrierTag"),
      hideSortIcon: true,
    },
    {
      id: "quote",
      disablePadding: false,
      label: t("table.headers.quote"),
      hideSortIcon: true,
    },
    {
      id: "getQuote",
      disablePadding: false,
      label: "",
      hideSortIcon: true,
    },
    {
      id: "delete",
      disablePadding: false,
      label: "",
      hideSortIcon: true,
    },
  ]

  const handleClickOrderRow = (order) => {
    setQuotationOpenModal(order)
  }

  // TODO: change shipping type options. Maybe remove "cheapest" to something better. Maybe Purolator -> Purolator only
  return (
    <Paper sx={{
      width: "100%",
      backgroundColor: "white",
      height: "100%",
      overflow: "hidden",

    }}>
      <TableContainer sx={{ height: "100%", overflow: "scroll" }}>
        <Table size="small" stickyHeader sx={{ backgroundColor: theme.palette.backgroundNeutralUnderground1.main }}>
          <EnhancedTableHead
            headCells={headCells}
            order={orderDirection}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {
              isFetching
              ?
              <>
                {Array(3)
                  .fill()
                  .map((_, index) => {
                    return (
                      <TableRow key={"".concat(index)}>
                        <TableCell colSpan={12}>
                          <Skeleton variant="rectangular" height={50} width="100%" />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </>
              :
                sortedAndPaginatedData
                .map((order) => <OrderRow key={order.rateRequestId} order={order} onClick={() => handleClickOrderRow(order)} />)
            }
          </TableBody>
        </Table>
        <CustomPagination
          count={Math.ceil(totalFilteredCount / rowsPerPage)}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          showFirstButton
          showLastButton />
      </TableContainer>
    </Paper>

)
}

export default QuotationsTable
