import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import RateRequestSummaryCard from "../rate-request-summary-card"
import { IMPERIAL, METRIC } from "../../services/units-conversion"

import { isPackagesComplete, isWeightsMatching } from "../quotations/utils"
import { CommercialInvoiceProps, PackagesProps } from "../../models/rate-request"
import { totalPackages, totalWeight } from "../../utils/packages"

function PackagesCard({
    packages,
    commercialInvoice,
    setShowRateRequestDrawer,
    isEditable,
    isValid: customIsPackagesComplete,
    ...sw
}) {
  const theme = useTheme()
  const { t } = useTranslation("quotations")

  const showRateRequestDrawer = () => {
    sw.stepWizard.goToNamedStep("packages")
    setShowRateRequestDrawer(true)
  }

  const summaryStatusProps = {
    variant: customIsPackagesComplete !== undefined
      ? (customIsPackagesComplete ? "success" : "warning")
      : (isPackagesComplete(packages) && isWeightsMatching(packages, commercialInvoice) ? "success" : "warning")
  }

  return (
    <RateRequestSummaryCard title={t("packagesTitle")} handleClick={showRateRequestDrawer} summaryStatusProps={summaryStatusProps} showStatus={isEditable} isClickable={isEditable}>
      {packages?.pieces?.length > 0 && (
        <Box mt={1} sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
            {t("packagesCard.totalPackages")}
            {totalPackages(packages?.pieces)}
          </Typography>
          <Typography sx={{ lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
            {t("packagesCard.totalWeight")}
            <Typography color={isWeightsMatching(packages, commercialInvoice) ? theme.palette.contentNeutralSecondary.main : "error"} fontWeight={isWeightsMatching(packages, commercialInvoice) ? "inherit" : "bold"} variant="bodySmall400">
              {totalWeight(packages?.pieces)} {packages.unitOfMeasure === IMPERIAL && "lb"}
              {packages.unitOfMeasure === METRIC && "kg"}
            </Typography>
          </Typography>
        </Box>
      )}
    </RateRequestSummaryCard>
  )
}

PackagesCard.propTypes = {
  packages: PackagesProps,
  commercialInvoice: CommercialInvoiceProps,
  setShowRateRequestDrawer: PropTypes.func,
  isEditable: PropTypes.bool,
  isValid: PropTypes.bool,
}

PackagesCard.defaultProps = {
  packages: undefined,
  commercialInvoice: undefined,
  setShowRateRequestDrawer: () => {},
  isEditable: true,
  isValid: undefined,
}

export default PackagesCard
