import React from "react"
import PropTypes from "prop-types"
import { Typography } from "@mui/material"
import { totalPackages } from "../../../../../../../utils/packages"

function PiecesInfo({ packages, packagesComplete }) {

  if (!packages || !packagesComplete) return null
  return (
    <Typography>{totalPackages(packages?.pieces)}</Typography>
  )
}


PiecesInfo.propTypes = {
  packages: PropTypes.instanceOf(Object),
  packagesComplete: PropTypes.bool.isRequired,
}

PiecesInfo.defaultProps = {
  packages: undefined,
}

export default PiecesInfo
