import React from "react"
import PropTypes from "prop-types"
import { Typography } from "@mui/material"
import { totalWeight } from "../../../../../../../utils/packages"
import { IMPERIAL, METRIC } from "../../../../../../../services/units-conversion"

function WeightInfo({ packages, packagesComplete }) {

  if (!packagesComplete || !packagesComplete) return null
  return (
    <Typography>
      {totalWeight(packages?.pieces).toFixed(1)} {packages?.unitOfMeasure === IMPERIAL && "lb"}
      {packages?.unitOfMeasure === METRIC && "kg"}
    </Typography>
  )
}


WeightInfo.propTypes = {
  packages: PropTypes.instanceOf(Object),
  packagesComplete: PropTypes.bool.isRequired,
}

WeightInfo.defaultProps = {
  packages: undefined,
}

export default WeightInfo
