import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import {
  SoeDrawer,
  SoeDrawerActions,
  SoeDrawerContainer,
  SoeDrawerContent,
  SoeDrawerHeader
} from "../../../soe-theme/src/components"
import PrintingQueueList from "./components/printing-queue-list"

function PrintingQueueDrawer({ showPrintLabelsDrawer, setShowPrintLabelsDrawer }) {

  const { t } = useTranslation("shipments")

  const handleClose = () => {
    setShowPrintLabelsDrawer(false)
  }


  return (
    <SoeDrawer showDrawer={showPrintLabelsDrawer} setShowDrawer={setShowPrintLabelsDrawer} anchor="right" keepMounted={false}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("printLabelsDrawer.title")}
          setShowDrawer={() => {
            setShowPrintLabelsDrawer(false)
          }}
        />
        <SoeDrawerContent>
          <PrintingQueueList />
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={[
            {
              action: handleClose,
              label: t("printLabelsDrawer.actions.close"),
              variant: "contained",
            },
          ]}
        />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

PrintingQueueDrawer.propTypes = {
  showPrintLabelsDrawer: PropTypes.bool.isRequired,
  setShowPrintLabelsDrawer: PropTypes.func.isRequired,
}


export default PrintingQueueDrawer
