import {
  useValidateManyQuery
} from "../../../quotations/components/payment/components/payment-validation/slice"

const useOrderValidations = (orders, shouldValidate) => {
  const { data, isLoading, error } = useValidateManyQuery(orders.map((order) => order.quote?.rateResponseId),
    {
      skip: (orders.length===0) || !shouldValidate,
    }
  )

  return { data, isLoading, error };
}

export default useOrderValidations;
